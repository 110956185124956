import React from "react";
import NavbarResult from "../NavbarResult";
import { Icon } from "@iconify/react";
import KategoriIq from "./KategoriIq";
import Copyright from "../../etc/Copyright";
import LoadingFull from "../../etc/LoadingFull";
import Assesment from "../../module/Assesment";
import Etc from "../../module/Etc";
import { withRouter } from "../../etc/withRouter";

class ResultIq extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            nama: "",
            get_auth: localStorage.getItem("user-rapidtest"),
            result_hasil: [],
            loading: true,
            max_value: [],
            tgl: "",
            data: {
                umum: "",
                flesi: "",
                berpikir: ""
            }
        }

        this.getHasil = this.getHasil.bind(this);

    }

    getHasil(get_auth) {

        const data = {
            id: this.props.location.state.data.id.toString(),
            name: this.props.location.state.data.name,
        }

        Assesment.getHasil(get_auth, data).then((result: any) => {

            if (result.response === true) {
                const d_arr = {
                    umum: "",
                    flesi: "",
                    berpikir: "",
                };

                result.data.result.data.map((el) => {
                    if (el.nama === "Kecerdasan Umum") {
                        d_arr.umum = el.keterangan;
                    } else if (el.nama === "Fleksibilitas Berpikir") {
                        d_arr.flesi = el.keterangan;
                    } else if (el.nama === "Cara Berpikir") {
                        d_arr.berpikir = el.keterangan;
                    }
                })

                this.setState(prevState => ({
                    result_hasil: result.data.result,
                    loading: false,
                    tgl: result.data.result.tanggal_pengerjaan,
                    ...prevState.data,
                    data: {
                        umum: d_arr.umum,
                        flesi: d_arr.flesi,
                        berpikir: d_arr.berpikir,
                    }
                }));
            }
        })
    }

    componentDidMount(): void {
        const get_auth = JSON.parse(this.state.get_auth);
        this.getHasil(this.state.get_auth);

        this.setState({
            nama: get_auth.nama_lengkap,
        });

    }

    render(): React.ReactNode {
        return (
            <>
                <LoadingFull display={this.state.loading} />

                <NavbarResult />
                <div className="container">
                    <div className="result-box">
                        <div className="result-title">
                            <h3>Hasil Asesmen IQ,</h3>
                            <h5>{this.state.nama}</h5>
                        </div>

                        <div className="d-flex mt-4 mb-4">
                            <div className="align-self-center">
                                <Icon icon={"clarity:date-solid"} className="text-purple" style={{ position: "relative", top: -2, fontSize: 18 }} />
                            </div>

                            <div className="ps-2">
                                <p className="mb-0 text-extrabold text-purple">Dikerjakan Tanggal {this.state.tgl}</p>
                            </div>
                        </div>

                        <div className="hr-custom"></div>

                        <div className="box-iq bg-green mt-3">
                            <div className="head">
                                <Icon icon={"bxs:brain"} className="icon-iq" />
                                <p>Kecerdasan Umum</p>
                                <h3>{this.state.data.umum}</h3>
                            </div>
                        </div>

                        <div className="box-iq bg-purple mt-3">
                            <div className="head">
                                <Icon icon={"fluent-emoji-high-contrast:thinking-face"} className="icon-iq" />
                                <p>Flesibilitas Berfikir</p>
                                <h3>{this.state.data.flesi}</h3>
                            </div>
                        </div>

                        <div className="box-iq bg-blue mt-3">
                            <div className="head">
                                <Icon icon={"icon-park-solid:thinking-problem"} className="icon-iq" />
                                <p>Cara Berfikir</p>
                                <h3>{this.state.data.berpikir}</h3>
                            </div>
                        </div>

                        <div className="hr-custom mt-3 mb-3"></div>

                        <KategoriIq data={this.state.result_hasil} />
                    </div>
                </div>

                <Copyright style={{ marginBottom: 40 }} />

            </>
        )
    }
}

export default withRouter(ResultIq);