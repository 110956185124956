import React from "react";
import NavbarHome from "../etc/NavbarHome";
import Progress from "../etc/Progress";
import CardRapid from "../etc/CardRapid";
import Copyright from "../etc/Copyright";
import NavigationBar from "../etc/NavigationBar";
import Sidebar from "../etc/Sidebar";
import Assesment from "../module/Assesment";
import LoadingFull from "../etc/LoadingFull";
import Helmet from 'react-helmet';
import Auth from "../module/Auth";

class RapidTes extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            isSidebar: false,
            nama: "",
            list_assesment: [],
            loading: true,
            asses: [],
            data_user: {
                nama: "",
                kelas: "",
                jurusan: "",
                sekolah: "",
                nis: "",
                img: "",
            }
        }

        this.openSidebar = this.openSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.getAssesment = this.getAssesment.bind(this);
        this.getProfile = this.getProfile.bind(this);

    }

    getProfile() {
        const data_auth = localStorage.getItem("user-rapidtest");
        Auth.checkAuth(data_auth).then((result: any) => {

            if (result.response === true) {
                this.setState(prevState => ({
                    data_user: {
                        ...prevState.data_user,
                        nama: result.data.result.info_pribadi.nama,
                        kelas: result.data.result.sekolah.Kelas,
                        jurusan: result.data.result.sekolah.Jurusan,
                        sekolah: result.data.result.sekolah.sekolah,
                        nis: result.data.result.sekolah.nis,
                        img: result.data.result.info_pribadi.foto
                    },
                    loading: false,
                }));
            }
        })
    }

    getAssesment() {
        const data_auth = localStorage.getItem("user-rapidtest");

        Assesment.getAssesment(data_auth).then((result: any) => {

            if (result.response === true) {
                const component = result.data.result.map(el => {
                    return (
                        <div className="col col-sm  mt-3">
                            <CardRapid title={el.name} pengerjaan={el.pengerjaan} data={el} img={`${el.name.toLowerCase().split(' ').join('_')}_card`} />
                        </div>
                    )
                });

                this.setState({
                    list_assesment: component,
                    loading: false,
                    asses: result.data.result
                })


            }
        })
    }

    componentDidMount(): void {
        this.getAssesment();
        const name = JSON.parse(localStorage.getItem("user-rapidtest"));
        this.setState({
            nama: name.nama_lengkap
        })
        this.getProfile();

    }

    openSidebar() {
        this.setState({
            isSidebar: true,
        })
    }

    closeSidebar() {
        this.setState({
            isSidebar: false
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Helmet>
                    <title>List Asesmen | Ambisius Meter</title>
                </Helmet>
                <LoadingFull display={this.state.loading} />
                <div className="container">
                    <NavbarHome img={this.state.data_user.img} openSidebar={this.openSidebar} nama={this.state.nama} />

                </div>

                <div className="box-progress">
                    <div className="box-progress-content">
                        <div className="container">
                            <h5 className="text-blacks">Progress Asesmen Kamu</h5>

                            <Progress result={this.state.asses} />

                        </div>
                    </div>
                </div>

                <div className="container">
                    <h5 className="text-blacks">Kerjakan Asesmen</h5>
                    <div className="box-rapidtest">
                        <div className="row">
                            {/* <div className="col col-sm">
                                <CardRapid title="Polagram" img="polagram_card" />
                            </div>
                            <div className="col col-sm">
                                <CardRapid title="Gaya Belajar" img="gaya_card" />
                            </div>
                            <div className="col col-sm">
                                <CardRapid title="RIASEC" img="riasec_card" />
                            </div>
                            <div className="col col-sm">
                                <CardRapid title="Kepribadian" img="kepribadian_card" />
                            </div>
                            <div className="col col-sm ">
                                <CardRapid title="IQ" img="iq_card" />
                            </div> */}
                            {this.state.list_assesment}
                            <div className="col col-sm"></div>
                        </div>
                    </div>
                </div>
                <Copyright style={{ marginBottom: 100 }} />
                <NavigationBar />
                <Sidebar isSidebar={this.state.isSidebar} closeSidebar={this.closeSidebar} nama={this.state.nama} img={this.state.data_user.img} nis={this.state.data_user.nis} />
            </>
        )
    }
}

export default RapidTes;