import { Icon } from "@iconify/react";
import React from "react";

class ImageResultRiasec extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            icon: [],
            name: []
        }
    }

    getIcon(values) {
        const data_icon = [
            {
                name: "Realistic",
                icon: "material-symbols:nature-people-rounded"
            },
            {
                name: "Investigative",
                icon: "icon-park-solid:brain"
            },
            {
                name: "Artistic",
                icon: "map:art-gallery"
            },
            {
                name: "Social",
                icon: "ri:chat-smile-3-fill"
            },
            {
                name: "Enterprising",
                icon: "dashicons:businessman"
            },
            {
                name: "Conventional",
                icon: "mingcute:target-fill"
            }
        ]
        var icon = ""
        data_icon.map(el => {
            if (el.name === values) {
                icon = el.icon
            }
        });

        return icon;
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.result !== this.props.result) {
            var icon = [];
            this.props.result.forEach(el => {
                const get_icon = this.getIcon(el.nama);
                icon.push(get_icon);
            });



            this.setState({
                icon: icon,
                name: [this.props.result[0].nama, this.props.result[1].nama]
            })
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="image-custom-result mt-4">
                    <div className="image-custom-content">
                        <div className="d-flex justify-content-center">
                            <div className="pe-2">
                                <Icon icon={this.state.icon[0]} className="icon-image-custom" />
                            </div>
                            <div>
                                <Icon icon={this.state.icon[1]} className="icon-image-custom" />
                            </div>
                        </div>

                        <p>Peminatan Asesmen RIASEC kamu adalah</p>
                        <h5>{this.state.name[0]} - {this.state.name[1]}</h5>
                    </div>
                </div>
            </>
        )
    }
}

export default ImageResultRiasec;