import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

class ModalIq extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            button: ""
        }

        this.handleLanjut = this.handleLanjut.bind(this);
    }

    handleLanjut() {
        clearInterval(this.props.time_func)
        const get_curr = JSON.parse(localStorage.getItem("current_soal"));
        const get_pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));

        const next = get_curr[2] + 1;

        if (get_curr[2] === get_pagination.jumlah) {

            this.setState({
                button: <button className="btn btn-primary">SELESAI</button>,
            })
        } else {
            get_curr[get_curr.indexOf(get_curr[2])] = next;
            localStorage.setItem("current_soal", JSON.stringify(get_curr));
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.props.isOpen} backdrop={"static"}>
                    <Modal.Body>
                        <div className="header-modal">
                            <img src="/images/modal_success.png" alt="" />
                        </div>
                        <div className="modal-title">
                            <p>Kamu Sudah Menyelesaikan Asesmen IQ Subtest {this.props.title}</p>
                        </div>
                        <div className="modal-message">
                            <div className="image-modal mt-4 mb-4">
                                <img src={`/images/iq_card.png`} width={80} alt="" />
                            </div>
                            Silahkan klik tombol “LANJUT” untuk mengerjakan Subtest selanjutnya.
                        </div>

                        <div className="mt-4 text-end">
                            <Link className="btn btn-primary text-uppercase ps-4 pe-4" onClick={this.handleLanjut} to={this.props.to}>Lanjut</Link>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ModalIq;