
class Etc {
    static isObjectEmpty(value: any) {
        return Object.values(value).every(x => x !== '');
    }

    static spliceArr(arr: any, chunkSize) {
        const res = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            res.push(chunk);
        }
        return res;
    }

    static limitWord(str, length, delim, appendix){
        if (str.length <= length) return str;
        
            var trimmedStr = str.substr(0, length+delim.length);
        
            var lastDelimIndex = trimmedStr.lastIndexOf(delim);
            if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);
        
            if (trimmedStr) trimmedStr += appendix;
            return trimmedStr;
    }

    static maxArr(arr) {
        return arr.reduce((prev, current)=> ( (prev.score > current.score) ? prev : current),0)
    }

    static getDesc(arr) {
        return arr.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
    }

    static sliceObject(obj, to, max) {
        return Object.fromEntries(
            Object.entries(obj).slice(to, max)
        )
    }

    static checkEmail(value: any) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regex.test(value);
    }
}

export default Etc;