import { Icon } from "@iconify/react";
import React from "react";
import Time from "./Time";

class NavbarTime extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }


    render(): React.ReactNode {
        return (
            <>
                <div className="container">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <Time timer={this.props.timer} />
                        </div>

                        {/* {
                            this.props.hal === 9 ? <div className="align-self-center">
                                <button className="btn btn-info btn-sm text-10 pt-2 pb-2" onClick={this.props.openModal}><Icon icon={"mdi:eye"} /> LIHAT GAMBAR</button>
                            </div> : <></>
                        } */}

                    </div>
                </div>
            </>
        )
    }
}

export default NavbarTime;