import React from "react";
import NavbarTes from "../NavbarTes";
import SoalPolagram from "./SoalPolagram";
import { Icon } from "@iconify/react";
import Copyright from "../../etc/Copyright";
import ModalResult from "../../modal/ModalResult";
import Assesment from "../../module/Assesment";
import LoadingFull from "../../etc/LoadingFull";
import Helmet from 'react-helmet';

class TesPolagram extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            data_auth: localStorage.getItem("user-rapidtest"),
            list_soal: [],
            button: "",
            loading: true,
            disabled: true,
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getSoal = this.getSoal.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleDisabled = this.handleDisabled.bind(this);
    }

    handleDisabled(dsb?) {

        const get_empty = JSON.parse(localStorage.getItem("arr_empty"));

        if (get_empty.length !== this.state.list_soal.length - 1) {
            this.setState({
                disabled: true,
            })
        } else {
            this.setState({
                disabled: false,
            })
        }
    }

    getSoal(page?) {
        const get_current = JSON.parse(localStorage.getItem("current_soal"));
        const data = {
            data: {
                id: get_current[0],
                name: get_current[1],
                halaman: ""
            },
            page: page === null ? get_current[2] : page,
        }
        this.setState({
            loading: true,
        });
        setTimeout(() => {
            Assesment.getSoal(this.state.data_auth, data).then((result: any) => {
                if (result.response === true) {
                    const getanswer = JSON.parse(localStorage.getItem("answer"));
                    var id_soal = [];
                    getanswer.forEach(el => {
                        id_soal.push({ id_pertanyaan: el[0], id_jawaban: el[1] });
                    });
                    var no = 1;
                    
                    if (page !== 1) {
                        no = 1 + ((page - 1) * 20)
                    } else {
                        no = 1;
                    }
                    const componen_soal = result.data.result.map((el, i) => {
                        if (el.id_pengiriman === undefined) {
                            var answer = null;
                            var curr = no++;
                            const filter = id_soal.filter(item => item.id_pertanyaan === el.id_pertanyaan);

                            if (filter.length !== 0) {
                                answer = [filter[0].id_pertanyaan, filter[0].id_jawaban]
                            } else {
                                answer = [el.id_pertanyaan, null]
                            }

                            return (
                                <>
                                    <SoalPolagram data_soal={el} no_soal={curr} answer={answer} handleDisabled={this.handleDisabled} />
                                </>
                            )
                        } else {

                            localStorage.setItem("pagination-rapidtest", JSON.stringify({ jumlah: el.pagination_soal, jumlah_soal: el.total_soal, pengiriman: el.id_pengiriman }))
                        }
                    });

                    this.setState({
                        list_soal: componen_soal,
                        loading: false,
                    });
                }
            })
        }, 1000)

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    handleNext() {
        const get_curr = JSON.parse(localStorage.getItem("current_soal"));
        const get_pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));

        const next = get_curr[2] + 1;

        if (get_curr[2] === get_pagination.jumlah) {

            this.setState({
                button: <button className="btn btn-primary" disabled={this.state.disabled} onClick={this.openModal}>SELESAI</button>,
            });
            localStorage.setItem("arr_empty", JSON.stringify([]))
        } else {
            get_curr[get_curr.indexOf(get_curr[2])] = next;
            this.setState({
                button: <button className="btn btn-info" onClick={this.handleNext} disabled={this.state.disabled}>BERIKUTNYA <Icon icon={"material-symbols:arrow-right-alt"} style={{ fontSize: 25, position: "relative", top: -3 }} /></button>
            })
            localStorage.setItem("current_soal", JSON.stringify(get_curr));
            this.getSoal(next);
            localStorage.setItem("arr_empty", JSON.stringify([]))
        }
    }

    componentDidMount(): void {
        const get_curr = JSON.parse(localStorage.getItem("current_soal"));
        const get_pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));
        this.getSoal(get_curr[2]);
        if (get_curr[2] === get_pagination.jumlah) {

            this.setState({
                button: <button className="btn btn-primary" onClick={this.openModal}>SELESAI</button>,
            })
        } else {
            this.setState({
                button: <button className="btn btn-info" onClick={this.handleNext} disabled={this.state.disabled}>BERIKUTNYA <Icon icon={"material-symbols:arrow-right-alt"} style={{ fontSize: 25, position: "relative", top: -3 }} /></button>
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });

        if (prevState.disabled !== this.state.disabled) {
            const get_curr = JSON.parse(localStorage.getItem("current_soal"));
            const get_pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));
            if (get_curr[2] === get_pagination.jumlah) {

                this.setState({
                    button: <button className="btn btn-primary" disabled={this.state.disabled} onClick={this.openModal}>SELESAI</button>,
                })
            } else {
                this.setState({
                    button: <button className="btn btn-info" onClick={this.handleNext} disabled={this.state.disabled}>BERIKUTNYA <Icon icon={"material-symbols:arrow-right-alt"} style={{ fontSize: 25, position: "relative", top: -3 }} /></button>
                })
            }
        }
    }

    openModal() {
        this.setState({
            isOpen: true,
        });
    }

    closeModal() {
        this.setState({
            isOpen: false,
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Helmet>
                    <title>Tes  Polagram | Ambisius Meter</title>
                </Helmet>
                <LoadingFull display={this.state.loading} />
                <NavbarTes title="Polagram" />
                <div className="container">
                    {
                        this.state.list_soal
                    }

                    <div className="d-grid mt-4 me-5 ms-5">
                        {
                            this.state.button
                        }
                    </div>
                </div>

                <Copyright style={{ marginBottom: 40 }} />
                <ModalResult isLoading={this.state.loading} onLoadingStart={() => this.setState({ loading: true })} isOpen={this.state.isOpen} closeModal={this.closeModal} to="/tes/polagram/result" title="Polagram" />
            </>
        )
    }
}

export default TesPolagram;