import React from "react";

class ResultValueKepribadian extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            line_1: "",
            line_2: "",
            line_3: "",
            line_4: "",
        }
    }

    getHasil() {
        if (this.props.result.length !== 0) {

            const line_1 = this.props.result.data.slice(0, 4)
            const line_2 = this.props.result.data.slice(4, 8)
            const line_3 = this.props.result.data.slice(8, 12)
            const line_4 = this.props.result.data.slice(12, 16)


            var i = 0;
            const hasil_1 = line_1.map((el) => {
                const no = i++;
                if (no === 0) {
                    return (
                        <>
                            <div className={`value-kepribadian-item width-88 bg-merah me-2`}>
                                <h5>{el.score}</h5>
                                <p>{el.singkatan}</p>
                            </div>
                        </>
                    )
                } else if (no === 1) {
                    return (
                        <>
                            <div className={`value-kepribadian-item width-88 bg-kuning me-2`}>
                                <h5>{el.score}</h5>
                                <p>{el.singkatan}</p>
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className={`value-kepribadian-item width-88 bg-${el.warna} me-2`}>
                                <h5>{el.score}</h5>
                                <p>{el.singkatan}</p>
                            </div>
                        </>
                    )
                }
            });

            const hasil_2 = line_2.map((el) => {
                return (
                    <>
                        <div className={`value-kepribadian-item width-88 bg-${el.warna} me-2`}>
                            <h5>{el.score}</h5>
                            <p>{el.singkatan}</p>
                        </div>
                    </>
                )
            });


            const hasil_3 = line_3.map((el) => {
                return (
                    <>
                        <div className={`value-kepribadian-item width-88 bg-${el.warna} me-2`}>
                            <h5>{el.score}</h5>
                            <p>{el.singkatan}</p>
                        </div>
                    </>
                )
            });

            const hasil_4 = line_4.map((el) => {
                return (
                    <>
                        <div className={`value-kepribadian-item width-88 bg-${el.warna} me-2`}>
                            <h5>{el.score}</h5>
                            <p>{el.singkatan}</p>
                        </div>
                    </>
                )
            });

            this.setState({
                line_1: hasil_1,
                line_2: hasil_2,
                line_3: hasil_3,
                line_4: hasil_4
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.result !== this.props.result) {
            this.getHasil();
        }
    }

    componentDidMount(): void {
        this.getHasil();
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="value-kepribadian mt-4">
                    <div className="value-kepribadian-content">
                        <div className="d-flex justify-content-center">
                            {
                                this.state.line_1
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            {
                                this.state.line_2
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            {
                                this.state.line_3
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            {
                                this.state.line_4
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ResultValueKepribadian;