import React from "react";

class AccordionKepribadian extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            comp: [],
        }

        this.getValue = this.getValue.bind(this);
    }

    getValue() {

        const data = this.props.data;

        const comps = data.data.map((el, i) => {
            return (
                <>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-${i}`} aria-expanded="true" aria-controls={`accordion-${i}`}>
                                <div className="accordion-icon"></div>
                                {el.nama} ({el.singkatan})
                            </button>
                        </h2>
                        <div id={`accordion-${i}`} className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                            <div className="accordion-body">
                                <span>{el.Keterangan}</span>
                            </div>
                        </div>
                    </div>
                </>
            )
        });

        this.setState({
            comp: comps,
        })
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.data !== this.props.data) {
            this.getValue();
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    {
                        this.state.comp
                    }

                </div>
            </>
        )
    }
}

export default AccordionKepribadian;