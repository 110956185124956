import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "../etc/withRouter";

class ModalConfirm extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            image: "",
        }

        this.getPicture = this.getPicture.bind(this);
    }

    getPicture() {
        if (this.props.status === "success") {
            this.setState({
                image: "./images/modal_success.png",
            })
        } else if (this.props.status === "danger") {
            this.setState({
                image: "./images/modal_danger.png",
            })
        } else if (this.props.status === "warning") {
            this.setState({
                image: "./images/modal_warning.png",
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.props.status !== prevProps.status) {
            this.getPicture();
        }
    }

    componentDidMount(): void {
        this.getPicture()
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.props.isOpen} onHide={this.props.closeModal}>
                    <Modal.Body>
                        <div className="header-modal">
                            <img src={this.state.image} alt="" />
                        </div>
                        <div className="modal-title">
                            <p>{this.props.title}</p>
                        </div>
                        <div className="modal-message">
                            {this.props.message}
                        </div>

                        <div className="d-flex">
                            <div className="p-2 flex-grow-1">
                                <Button className="btn-no-border" onClick={this.props.closeModal}>CANCEL</Button>
                            </div>
                            <div className="p-2">
                                <Link to={'/dashboard'} onClick={this.props.handleSimpan} className="btn btn-primary ps-4 pe-4">SIMPAN</Link>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withRouter(ModalConfirm);