import React from "react";
import NavbarResult from "../NavbarResult";
import { Icon } from "@iconify/react";
import ResultValueGayaBelajar from "./ResultValueGayaBelajar";
import AccordionGayaBelajar from "./AccordionGayaBelajar";
import Copyright from "../../etc/Copyright";
import Assesment from "../../module/Assesment";
import { withRouter } from "../../etc/withRouter";
import Etc from "../../module/Etc";
import LoadingFull from "../../etc/LoadingFull";

class ResultGayaBelajar extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            nama: "",
            get_auth: localStorage.getItem("user-rapidtest"),
            result_hasil: [],
            picture: "",
            loading: true,
            tgl: ""
        }

        this.getHasil = this.getHasil.bind(this);

    }

    getHasil(get_auth) {

        const data = {
            id: this.props.location.state.data.id.toString(),
            name: this.props.location.state.data.name,
        }

        Assesment.getHasil(get_auth, data).then((result: any) => {

            if (result.response === true) {
                const getMax = Etc.maxArr(result.data.result.data);

                this.setState({
                    result_hasil: result.data.result,
                    picture: getMax.nama.toLowerCase(),
                    loading: false,
                    tgl: result.data.result.tanggal_pengerjaan
                });


            }
        })
    }

    componentDidMount(): void {
        const get_auth = JSON.parse(this.state.get_auth);
        this.getHasil(this.state.get_auth);

        this.setState({
            nama: get_auth.nama_lengkap,
        });

    }

    render(): React.ReactNode {
        return (
            <>
                <NavbarResult />
                <LoadingFull display={this.state.loading} />

                <div className="container">
                    <div className="result-box">
                        <div className="result-title">
                            <h3>Hasil Asesmen Gaya Belajar,</h3>
                            <h5>{this.state.nama}</h5>
                        </div>

                        <div className="d-flex mt-4 mb-4">
                            <div className="align-self-center">
                                <Icon icon={"clarity:date-solid"} className="text-purple" style={{ position: "relative", top: -2, fontSize: 18 }} />
                            </div>

                            <div className="ps-2">
                                <p className="mb-0 text-extrabold text-purple">Dikerjakan Tanggal {this.state.tgl}</p>
                            </div>
                        </div>

                        <div className="hr-custom"></div>
                        {
                            this.state.loading && <><div className="d-flex align-items-center mt-4">
                                <strong>Sedang Mengambil Data...</strong>
                                <div className="spinner-border ms-auto text-primary" role="status" aria-hidden="true"></div>
                            </div></>
                        }
                        <ResultValueGayaBelajar result={this.state.result_hasil} />

                        <div className="banner-kepribadian mt-4 mb-4">
                            <img src={`/images/banner_gaya/${this.state.picture}.png`} alt="" />
                        </div>

                        <div className="mt-4">
                            <h3 className="text-blacks mb-3">Keterangan Warna</h3>
                            <ul className="list-group">
                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg bg-red"></div>
                                            <span>Merah</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">Gaya Belajar Sangat Dominan</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg bg-yellow"></div>
                                            <span>Kuning</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">Gaya Belajar Dominan</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg"></div>
                                            <span>Putih</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">Gaya Belajar Kurang Dominan</p>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>

                        <div className="mt-4">
                            <h3 className="text-blacks mb-3">Penjelasan Tipe kepribadian</h3>
                            <AccordionGayaBelajar data={this.state.result_hasil} />
                        </div>

                    </div>
                </div>

                <Copyright style={{ marginBottom: 40 }} />

            </>
        )
    }
}

export default withRouter(ResultGayaBelajar);