import React from "react";


class NavbarTitle extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="navbar-title">
                    <div className="navbar-title-content">
                        <div className="d-flex">
                            <div className="flex-grow-1 align-self-center">
                                <div className="navbar-titles">
                                    <h5>Profile Kamu</h5>
                                </div>
                            </div>

                            <div className="" onClick={this.props.openSidebar}>
                                <img src="./images/sidebar_icon.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NavbarTitle;