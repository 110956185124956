import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

class ModalConfirmProfile extends React.Component<any, any> {
    constructor(props) {
        super(props);

    }



    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.props.isOpen} onHide={this.props.closeModal}>
                    <Modal.Body>
                        <div className="header-modal">
                            <img src="/images/modal_warning.png" alt="" />
                        </div>
                        <div className="modal-title">
                            <p>Apakah kamu yakin ingin menyimpan perubahan profile?</p>
                        </div>
                        <div className="modal-message">
                            Setelah meng-klik tombol “SIMPAN” data yang kamu ubah tidak bisa kembali lagi.
                        </div>

                        <div className="d-flex">
                            <div className="p-2 flex-grow-1">
                                <Button className="btn-no-border" onClick={this.props.closeModal}>CANCEL</Button>
                            </div>
                            <div className="p-2">
                                <Button className="btn btn-primary ps-4 pe-4" onClick={this.props.handleSimpan}>SIMPAN</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ModalConfirmProfile;