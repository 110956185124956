import React from "react";
import NavbarIsi from "./NavbarIsi";
import FooterButton from "./FooterButton";
import HrCustom from "../etc/HrCustom";
import { Button } from "react-bootstrap";
import ProfileModule from "../module/Profile";
import { WithContext as ReactTags } from 'react-tag-input';
import { withRouter } from "../etc/withRouter";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class Step2 extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            tags_cita: [],
            tags_kesukaan: [],
            value_cita: "",
            value_kesukaan: "",
            cita: [],
            kesukaan: [],
            suggest_cita: [{ id: "", text: "" }],
            suggest_kesukaan: [{ id: "", text: "" }],
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeKesukaan = this.handleInputChangeKesukaan.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdditionKesukaan = this.handleAdditionKesukaan.bind(this);
        this.handleDeleteKesukaan = this.handleDeleteKesukaan.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.getSuggestionCita = this.getSuggestionCita.bind(this);
        this.getSuggestionKesukaan = this.getSuggestionKesukaan.bind(this);
        this.getLocal = this.getLocal.bind(this);
    }

    getSuggestionCita() {
        const data_auth = localStorage.getItem("user-rapidtest");
        ProfileModule.getCita(data_auth).then((result: any) => {
            if (result.response === true) {
                const data_cita = result.data.map(el => {

                    return {
                        id: el.name,
                        text: el.name,
                    }
                });

                this.setState({
                    suggest_cita: data_cita
                })
            }
        })
    }

    getSuggestionKesukaan() {
        const data_auth = localStorage.getItem("user-rapidtest");
        ProfileModule.getKesukaan(data_auth).then((result: any) => {
            if (result.response === true) {
                const data_kesukaan = result.data.map(el => {
                    return {
                        id: el.name,
                        text: el.name,
                    }
                });

                this.setState({
                    suggest_kesukaan: data_kesukaan
                })
            }
        })
    }

    handleAddition(tag) {
        if (this.state.tags_cita.length !== 3) {
            this.setState(prevState => ({
                tags_cita: [...prevState.tags_cita, tag],
                value_cita: "",
                value_kesukaan: "",
            }), () => {
                const cita_arr = [];

                this.state.tags_cita.map(el => {
                    cita_arr.push(el.text)
                })

                this.setState({
                    cita: cita_arr,
                })

            });
        }
        const input = document.getElementById("cita_input") as HTMLInputElement
        input.value = "";
    }

    handleDelete(i) {
        const fil = this.state.tags_cita.filter((tag, index) => index !== i);

        this.setState(prevState => ({
            tags_cita: fil
        }))
    }

    handleAdditionKesukaan(tag) {
        if (this.state.tags_kesukaan.length !== 3) {

            this.setState(prevState => ({
                tags_kesukaan: [...prevState.tags_kesukaan, tag],
                value_cita: "",
                value_kesukaan: "",
            }), () => {
                const kesukaan_arr = [];

                this.state.tags_kesukaan.map(el => {
                    kesukaan_arr.push(el.text)
                })

                this.setState({
                    kesukaan: kesukaan_arr,
                })

            });
        }
        const input = document.getElementById("kesukaan_input") as HTMLInputElement
        input.value = "";
    }

    handleDeleteKesukaan(i) {
        const fil = this.state.tags_kesukaan.filter((tag, index) => index !== i);

        this.setState(prevState => ({
            tags_kesukaan: fil
        }))
    }

    handleInputChange(e) {
        this.setState({
            value_cita: e
        });
    }

    handleInputChangeKesukaan(e) {
        this.setState({
            value_kesukaan: e
        });
    }

    handleNext() {
        const cita_arr = [];
        const kesukaan_arr = [];

        this.state.tags_cita.map(el => {
            cita_arr.push(el.text)
        });

        this.state.tags_kesukaan.map(el => {
            kesukaan_arr.push(el.text)
        });

        const get_local = JSON.parse(localStorage.getItem("step_profile"))
        get_local.cita_cita = cita_arr;
        get_local.kesukaan = kesukaan_arr;

        localStorage.setItem("step_profile", JSON.stringify(get_local))
    }

    getLocal() {
        const get_local = JSON.parse(localStorage.getItem("step_profile"));
        const cita = get_local.cita_cita.map(el => {
            return {
                id: el,
                text: el,
            }
        });

        const kesukaan = get_local.kesukaan.map(el => {
            return {
                id: el,
                text: el,
            }
        });

        this.setState({
            tags_cita: cita,
            tags_kesukaan: kesukaan
        })
    }

    componentDidMount(): void {
        this.getSuggestionCita();
        this.getSuggestionKesukaan();
        this.getLocal();
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="container">
                    <NavbarIsi step={2} />

                    <div className="box-profile">
                        <h5>Ceritakan Impian dan Hobi Kamu.</h5>

                        <HrCustom icon="rocket" />

                        <div className="isi-pp mt-4">
                            <div className="form-group">
                                <label htmlFor="">Cita - Cita</label>
                                <div>
                                    <div className="btn-tags-container">
                                        <Button className="btn btn-primary btn-tags btn-sm" onClick={() => this.handleAddition({ id: this.state.value_cita, text: this.state.value_cita })}>Tambah</Button>
                                    </div>
                                    <ReactTags
                                        tags={this.state.tags_cita}
                                        id="cita_input"
                                        suggestions={this.state.suggest_cita}
                                        delimiters={delimiters}
                                        handleAddition={this.handleAddition}
                                        inputFieldPosition="top"
                                        handleDelete={this.handleDelete}
                                        autocomplete
                                        placeholder={"Masukan Cita Cita Kamu"}
                                        handleInputChange={this.handleInputChange}
                                    />

                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">Kesukaan</label>
                                <div>
                                    <div className="btn-tags-container">
                                        <Button className="btn btn-primary btn-tags btn-sm" onClick={() => this.handleAdditionKesukaan({ id: this.state.value_kesukaan, text: this.state.value_kesukaan })}>Tambah</Button>
                                    </div>
                                    <ReactTags
                                        tags={this.state.tags_kesukaan}
                                        suggestions={this.state.suggest_kesukaan}
                                        delimiters={delimiters}
                                        handleAddition={this.handleAdditionKesukaan}
                                        inputFieldPosition="top"
                                        handleDelete={this.handleDeleteKesukaan}
                                        autocomplete
                                        placeholder={"Masukan Hobi/Kesukaan Kamu"}
                                        handleInputChange={this.handleInputChangeKesukaan}
                                        id="kesukaan_input"

                                    />


                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <FooterButton to="/step3" openModal={this.handleNext} to_2="/step1" btn_second="Kembali" />
            </>
        )
    }
}

export default withRouter(Step2);