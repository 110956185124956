import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

class NavbarResult extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="container">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <Link to={"/asesmen"} className="btn btn-primary btn-sm pe-3 pt-3 pb-3"><Icon icon={"material-symbols:chevron-left-rounded"} style={{ fontSize: 20, position: 'relative', top: -1 }} /> KEMBALI</Link>
                        </div>

                        <div className="align-self-center">
                            <img  style={{ width: "256px" }}  src="/images/logo.png" alt="" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NavbarResult;