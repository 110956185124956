import React from "react";
import { withRouter } from "../etc/withRouter";
import Copyright from "../etc/Copyright";
import { Link } from "react-router-dom";
import InfoSoal from "../etc/InfoSoal";
import Assesment from "../module/Assesment";
import Etc from "../module/Etc";
import LoadingFull from "../etc/LoadingFull";
import Helmet from 'react-helmet';

class DetailTes extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            data_tes: {
                id: "",
                name: "",
                desc: "",
                intruksi: "",
                total_soal: "",
                waktu: "",
            },
            desc_limit: "",
            loading: true,
            total_soal_hal: "",
        }

        this.getDetail = this.getDetail.bind(this);
        this.setCurrent = this.setCurrent.bind(this);
        this.handleMore = this.handleMore.bind(this);
    }

    getDetail() {
        const data_auth = localStorage.getItem("user-rapidtest");

        Assesment.getDetail(data_auth, { id: this.props.location.state.data.id, name: this.props.location.state.data.name }).then((result: any) => {


            if (result.response === true) {
                this.setState(prevState => ({
                    data_tes: {
                        ...prevState.data_tes,
                        id: result.data.result.id,
                        name: result.data.result.name,
                        desc: result.data.result.desc,
                        intruksi: result.data.result.intruksi,
                        total_soal: result.data.result.total_soal,
                        waktu: result.data.result.waktu,
                    },
                    desc_limit: Etc.limitWord(result.data.result.desc, 200, ' ', '. . . . .'),
                    loading: false,
                }), () => {


                })
            }
        })
    }

    componentDidMount(): void {
        this.getDetail();
    }

    setCurrent() {
        const data_auth = localStorage.getItem("user-rapidtest");

        const arr_new: any = [];
        const data = {
            data: {
                id: this.state.data_tes.id,
                name: this.state.data_tes.name,
                halaman: ""
            },
            page: 1,
        }

        Assesment.getSoal(data_auth, data).then((result: any) => {

            if (result.response === true) {

                localStorage.setItem("arr_empty", JSON.stringify([]))
                localStorage.setItem("next_soal", JSON.stringify(result.data.result.length - 1));
            }
        });
        arr_new.push(this.state.data_tes.id, this.state.data_tes.name, 1);


        localStorage.setItem("current_soal", JSON.stringify(arr_new));
        localStorage.setItem("answer", JSON.stringify([]));
        localStorage.setItem("pagination-rapidtest", JSON.stringify([]));
        localStorage.setItem("total_soal", "0");
    }

    handleMore() {
        this.setState(prevState => ({
            desc_limit: this.state.data_tes.desc,

        }))
    }

    render(): React.ReactNode {
        return (
            <>
                <LoadingFull display={this.state.loading} />
                <Helmet>
                    <title>Detail {this.state.data_tes.name} | Ambisius Meter</title>
                </Helmet>
                <div className={`detail_tes gradient-${this.state.data_tes.name.toLowerCase().split(' ').join('_')}_card`}>
                    <div className="container">
                        <div className="detail_tes_header">
                            <Link to="/asesmen"><img src="./images/back.png" alt="" /></Link>

                            <div className="mt-4">
                                <h1 className="text-blacks">Asesmen {this.state.data_tes.name}</h1>
                            </div>
                        </div>

                        <div className="detail-tes-body">
                            <div className="detail-tes-message">
                                <p>{this.state.desc_limit}</p>
                                <span className="text-primarys text-14 text-extrabold" onClick={this.handleMore}>Lihat Lebih Banyak &gt;&gt;</span>
                            </div>

                            <div className="detail-tes-img">
                                <img src={`./images/${this.state.data_tes.name.toLowerCase().split(' ').join('_') + "_card"}.png`} alt="" />
                            </div>
                        </div>


                        <div className="detail-tes-desc">
                            <h5 className="text-blacks">Instruksi Pengerjaan Asesmen</h5>

                            <InfoSoal data_tes={this.state.data_tes} />

                            <p className="text-14 mt-4">{this.state.data_tes.intruksi}</p>

                            <div className="d-grid mt-5 mb-5">
                                <Link to={this.props.location.state.to} onClick={this.setCurrent} className="btn btn-primary">MULAI ASESMEN</Link>
                            </div>
                        </div>

                        <Copyright />

                    </div>

                </div>
            </>
        )
    }
}

export default withRouter(DetailTes);