import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

class ModalButton extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            image: "",
        }

        this.getPicture = this.getPicture.bind(this);
    }

    getPicture() {
        if (this.props.status === "success") {
            this.setState({
                image: "./images/modal_success.png",
            })
        } else if (this.props.status === "danger") {
            this.setState({
                image: "./images/modal_danger.png",
            })
        } else if (this.props.status === "warning") {
            this.setState({
                image: "./images/modal_warning.png",
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.props.status !== prevProps.status) {
            this.getPicture();
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.props.isOpen}>
                    <Modal.Body>
                        <div className="header-modal">
                            <img src="./images/modal_success.png" alt="" />
                        </div>
                        <div className="modal-title">
                            <p>{this.props.title}</p>
                        </div>
                        <div className="modal-message">
                            {this.props.message}
                        </div>

                        <div className="mt-4 text-end">
                            <Link className="btn btn-primary text-uppercase ps-4 pe-4" to={this.props.to}>Lanjut</Link>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ModalButton;