import React from "react";
import Helmet from 'react-helmet';
import NavbarTitle from "../etc/NavbarTitle";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import InfoSekolah from "../etc/InfoSekolah";
import Copyright from "../etc/Copyright";
import NavigationBar from "../etc/NavigationBar";
import Sidebar from "../etc/Sidebar";
import { Link } from "react-router-dom";
import ModalLogout from "../modal/ModalLogout";
import Auth from "../module/Auth";
import LoadingFull from "../etc/LoadingFull";

class Profile extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            isSidebar: false,
            isOpen: false,
            data_user: {
                kelas: "",
                jurusan: "",
                nama: "",
                nis: "",
                sekolah: "",
            },
            data_pribadi: {
                email: "",
                no_telp: "",
                ttl: "",
                jk: "",
                alamat: "",
            },
            cita: [],
            kesukaan: [],
            loading: true,
            img: "",
        }

        this.openSidebar = this.openSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getProfile = this.getProfile.bind(this);

    }

    getProfile() {
        const data_auth = localStorage.getItem("user-rapidtest");
        Auth.checkAuth(data_auth).then((result: any) => {

            if (result.response === true) {
                this.setState(prevState => ({
                    data_user: {
                        ...prevState.data_user,
                        nama: result.data.result.info_pribadi.nama,
                        kelas: result.data.result.sekolah.Kelas.nama_kelas,
                        jurusan: result.data.result.sekolah.Jurusan.nama_jurusan,
                        sekolah: result.data.result.sekolah.sekolah,
                        nis: result.data.result.sekolah.nis
                    },
                    data_pribadi: {
                        ...prevState.data_pribadi,
                        email: result.data.result.info_pribadi.email,
                        no_telp: result.data.result.info_pribadi.no_hp,
                        ttl: result.data.result.info_pribadi.tgl_lahir,
                        jk: result.data.result.info_pribadi.gender,
                        alamat: result.data.result.info_pribadi.alamat,

                    },
                    cita: result.data.result.Kesukaan.cita.toString(),
                    kesukaan: result.data.result.Kesukaan.kesukaan.toString(),
                    loading: false,
                    img: result.data.result.info_pribadi.foto,

                }));
            }
        })
    }

    componentDidMount(): void {
        this.getProfile();
        const name = JSON.parse(localStorage.getItem("user-rapidtest"));
        this.setState({
            nama: name.nama_lengkap
        })
    }

    openSidebar() {
        this.setState({
            isSidebar: true,
        })
    }

    closeSidebar() {
        this.setState({
            isSidebar: false
        })
    }

    closeModal() {
        this.setState({
            isOpen: false,
        })
    }

    openModal() {
        this.setState({
            isOpen: true,
        })
    }



    render(): React.ReactNode {
        return (
            <>
                <Helmet>
                    <title>Profile | Ambisius Meter</title>
                </Helmet>

                <LoadingFull display={this.state.loading} />
                <div className="container">
                    <NavbarTitle openSidebar={this.openSidebar} />

                    <div className="isi-pp mt-4">
                        <div className="substract-1">
                            <img src="./images/circle_profile.png" alt="" />
                        </div>
                        <div className="substract-2">
                            <img src="./images/circle_profile.png" alt="" />
                        </div>

                        <div className="text-center img-isi-pp">
                            <img src={this.state.img} alt="" />
                            <div className="mt-3">
                                <h3>{this.state.data_user.nama}</h3>
                                <Link className="btn btn-primary btn-sm pt-2 pb-2 ps-3 pe-3 mt-1" to={"/edit_profile"}><Icon icon={"ci:edit"} /> EDIT PROFILE</Link>
                            </div>
                        </div>
                    </div>

                    <div className="box-impian">
                        <div className="box-impian-content">
                            <h5 className="text-blacks mb-3">Impian & Kesukaanmu</h5>

                            <ul className="list-group">
                                <li className="list-group-item bg-blue-sky-2 mb-2">
                                    <div className="d-flex">
                                        <div className="align-self-center pe-2">
                                            <img src="./images/citacita.png" alt="" />
                                        </div>
                                        <div>
                                            <div className="profile-list-content">
                                                <span className="pt-12">Cita - Cita</span>
                                                <p className="pt-14">{this.state.cita.length === 0 ? "Tidak Ada" : this.state.cita}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item bg-blue-sky-2 mb-2">
                                    <div className="d-flex">
                                        <div className="align-self-center pe-2">
                                            <img src="./images/kesukaan.png" alt="" />
                                        </div>
                                        <div>
                                            <div className="profile-list-content">
                                                <span className="pt-12">Kesukaan</span>
                                                <p className="pt-14">{this.state.kesukaan.length === 0 ? "Tidak Ada" : this.state.kesukaan}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="box-purple-flat">
                    <div className="box-purple-flat-content">
                        <div className="container">
                            <h5 className="text-blacks mb-3">Info Sekolah</h5>

                            <InfoSekolah data_user={this.state.data_user} />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="box-pribadi">
                        <div className="box-pribadi-content">
                            <h5 className="text-blacks mb-3">Info Pribadi</h5>

                            <ul className="list-group">
                                <li className="list-group-item box-shadow mb-2">
                                    <div className="d-flex">
                                        <div className="align-self-center pe-2">
                                            <img src="./images/email.png" alt="" />
                                        </div>
                                        <div>
                                            <div className="profile-list-content">
                                                <span>Email</span>
                                                <p>{this.state.data_pribadi.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item box-shadow mb-2">
                                    <div className="d-flex">
                                        <div className="align-self-center pe-2">
                                            <img src="./images/telp.png" alt="" />
                                        </div>
                                        <div>
                                            <div className="profile-list-content">
                                                <span>Nomor Telepon</span>
                                                <p>{this.state.data_pribadi.no_telp}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item box-shadow mb-2">
                                    <div className="d-flex">
                                        <div className="align-self-center pe-2">
                                            <img src="./images/tanggal_lahir.png" alt="" />
                                        </div>
                                        <div>
                                            <div className="profile-list-content">
                                                <span>Tanggal Lahir</span>
                                                <p>{this.state.data_pribadi.ttl}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item box-shadow mb-2">
                                    <div className="d-flex">
                                        <div className="align-self-center pe-2">
                                            <img src="./images/jenis_kelamin.png" alt="" />
                                        </div>
                                        <div>
                                            <div className="profile-list-content">
                                                <span>Jenis Kelamin</span>
                                                <p>{this.state.data_pribadi.jk === "L" ? "Laki-Laki" : "Perempuan"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item box-shadow mb-2">
                                    <div className="d-flex">
                                        <div className="align-self-center pe-2">
                                            <img src="./images/alamat.png" alt="" />
                                        </div>
                                        <div>
                                            <div className="profile-list-content">
                                                <span>Alamat</span>
                                                <p>{this.state.data_pribadi.alamat}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="bg-danger-gradient d-grid">
                    <button className="btn btn-danger" onClick={this.openModal}><Icon icon={"ri:logout-circle-fill"} /> LOGOUT</button>
                </div>

                <Copyright style={{ marginBottom: 100 }} />
                <NavigationBar />
                <Sidebar isSidebar={this.state.isSidebar} nama={this.state.nama} closeSidebar={this.closeSidebar} nis={this.state.data_user.nis} img={this.state.img} />
                <ModalLogout isOpen={this.state.isOpen} closeModal={this.closeModal} />
            </>
        )
    }
}

export default Profile;