const data_kategori = [
    {
        nama: "Kecerdasan Verbal",
        warna: "",
        keterangan: "",
        icon: "fluent:text-case-title-24-filled"
    },
    {
        nama: "Kecerdasan Angka",
        warna: "",
        keterangan: "",
        icon: "mdi:numeric"
    },
    {
        nama: "Kecerdasan Figural",
        warna: "",
        keterangan: "",
        icon: "mdi:shape"
    },
    {
        nama: "Keceradasan Memori",
        warna: "",
        keterangan: "",
        icon: "fluent:brain-circuit-20-filled"
    },
    {
        nama: "Berpikir Komprehensif",
        warna: "",
        keterangan: "",
        icon: "mdi:head-thinking"
    },
    {
        nama: "Kemampuan Analisis",
        warna: "",
        keterangan: "",
        icon: "mdi:google-analytics"
    },
    {
        nama: "Pengambilan Keputusan ",
        warna: "",
        keterangan: "",
        icon: "mdi:arrow-decision"
    },
    {
        nama: "Kemampuan Berbahasa",
        warna: "",
        keterangan: "",
        icon: "noto-v1:speaking-head"
    },
];

export default data_kategori;