import React from "react";

class SoalEssay extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            values: "",
            total_soal: 0,
            list_soal: [],
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(): void {
        const get_pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));
        this.setState({
            total_soal: get_pagination.jumlah_soal,
        });


        if (this.props.answer[1] !== null) {
            if (this.props.data_soal.id_pertanyaan === this.props.answer[0]) {
                this.setState({
                    values: this.props.answer[1].toString()
                });
            }
        }
    }

    handleChange(e) {
        const data_in = e.target.value;

        this.setState({
            values: data_in
        });

        const getanswer = localStorage.getItem("answer");
        // [[id_pertanyaan, id_jawaban]]
        const arr_empty = JSON.parse(localStorage.getItem("arr_empty"));

        const pastanswer = JSON.parse(getanswer);
        const soal_id = [];


        // get id_pertanyaan
        pastanswer.forEach(el => {
            soal_id.push(el[0]);
        });

        if (pastanswer.length === 0) {
            pastanswer.push([this.props.data_soal.id_pertanyaan, data_in]);
            arr_empty.push([this.props.data_soal.id_pertanyaan, data_in]);


        } else {
            const filter_answer = soal_id.some((el) => el === this.props.data_soal.id_pertanyaan);

            if (filter_answer) {
                pastanswer.map(el1 => {
                    if (el1[0] === this.props.data_soal.id_pertanyaan) {

                        el1[1] = data_in;
                    }
                });
            } else {
                pastanswer.push([this.props.data_soal.id_pertanyaan, data_in]);
                arr_empty.push([this.props.data_soal.id_pertanyaan, data_in]);

            }
            // set
        }
        localStorage.setItem("answer", JSON.stringify(pastanswer));
        localStorage.setItem("arr_empty", JSON.stringify(arr_empty))
        this.props.handleDisabled(false);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.data_soal !== this.props.data_soal) {
            this.props.handleDisabled(true);

        }
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="soal-gaya mt-3">
                    <div className="soal-gaya-content">
                        <span>Pertanyaan {this.props.no_soal} dari {this.state.total_soal} | Subtest {this.props.hal}</span>
                        <p className="text-bold text-black" dangerouslySetInnerHTML={{ __html: this.props.data_soal.soal }}></p>

                        <div className="general-choose">
                            <input type="text" value={this.state.values} onChange={this.handleChange} className="form-control" />
                        </div>
                    </div>
                    <div className="hr-custom mt-3"></div>
                </div>
            </>
        )
    }
}

export default SoalEssay;