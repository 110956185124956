import React from "react";
import NavbarIsi from "./NavbarIsi";
import FooterButton from "./FooterButton";
import ModalConfirm from "../modal/ModalConfirm";
import HrCustom from "../etc/HrCustom";
import { withRouter } from "../etc/withRouter";
import Auth from "../module/Auth";
import LoadingFull from "../etc/LoadingFull";
import ProfileModule from "../module/Profile";
import Etc from "../module/Etc";

class Step4 extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            data: {
                nama: "",
                email: "",
                no_telp: "",
                ttl: "",
                jk: "",
                alamat: "",
            },

            loading: true,
            disabled: true,
        }



        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.handleSekolah = this.handleSekolah.bind(this);
        this.handleKelas = this.handleKelas.bind(this);
        this.handleJurusan = this.handleJurusan.bind(this);
        this.handleNama = this.handleNama.bind(this);
        this.handleTelp = this.handleTelp.bind(this);
        this.handleTtl = this.handleTtl.bind(this);
        this.handleJk = this.handleJk.bind(this);
        this.handleAlamat = this.handleAlamat.bind(this);
        this.handleSimpan = this.handleSimpan.bind(this);
        this.validated = this.validated.bind(this);
    }

    validated() {
        setTimeout(() => {
            if (Etc.isObjectEmpty(this.state.data)) {
                this.setState({ disabled: false });
            } else {
                this.setState({ disabled: true });
            }
        }, 1000)
    }

    getProfile() {
        this.setState({
            loading: true,
        })
        const data_auth = localStorage.getItem("user-rapidtest");
        Auth.checkAuth(data_auth).then((result: any) => {
            if (result.response === true) {
                this.setState(prevState => ({
                    data: {
                        ...prevState.data,
                        nama: result.data.result.info_pribadi.nama,
                        email: result.data.result.info_pribadi.email,
                        no_telp: result.data.result.info_pribadi.no_hp,
                        ttl: result.data.result.info_pribadi.tgl_lahir,
                        jk: result.data.result.info_pribadi.gender,
                        alamat: result.data.result.info_pribadi.alamat,
                    },
                    loading: false,
                }))
            }
        });
    }

    openModal() {
        this.setState({
            isOpen: true,
        });
    }

    closeModal() {
        this.setState({
            isOpen: false,
        })
    }

    handleSekolah(e) {
        this.setState(prevState => ({
            data_user: {
                ...prevState.data_user,
                sekolah: e.target.value,
            }
        }));

        this.validated();
    }

    handleKelas(e) {
        this.setState(prevState => ({
            data_user: {
                ...prevState.data_user,
                kelas: {
                    id_kelas: e.target.value
                },
            }
        }));
        this.validated();
    }

    handleJurusan(e) {
        this.setState(prevState => ({
            data_user: {
                ...prevState.data_user,
                jurusan: {
                    id_jurusan: e.target.value
                },
            }
        }));
        this.validated();
    }

    handleNama(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                nama: e.target.value,
            }
        }));
        this.validated();
    }

    handleTelp(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                no_telp: e.target.value,
            }
        }));
        this.validated();
    }

    handleTtl(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                ttl: e.target.value,
            }
        }));
        this.validated();
    }

    handleJk(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                jk: e.target.value,
            }
        }));
        this.validated();
    }

    handleAlamat(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                alamat: e.target.value,
            }
        }));
        this.validated();
    }

    componentDidMount(): void {
        this.getProfile();
    }

    handleSimpan() {
        const data_auth = localStorage.getItem("user-rapidtest");

        const change_auth = JSON.parse(data_auth);
        const get_local = JSON.parse(localStorage.getItem("step_profile"));
        change_auth.kelengkapan_profile = true;
        localStorage.setItem("user-rapidtest", JSON.stringify(change_auth));



        const data = {
            name: this.state.data.nama,
            address: this.state.data.alamat,
            phone: this.state.data.no_telp,
            date: this.state.data.ttl,
            jenkel: this.state.data.jk,
            cita_cita: get_local.cita_cita,
            kesukaan: get_local.kesukaan,
            school: get_local.school,
            kelas: get_local.kelas.toString(),
            jurusan: get_local.jurusan.toString(),
        }



        ProfileModule.sendChange(data_auth, data).then((result: any) => {

            if (result.response === true) {
                localStorage.setItem("step_profile", JSON.stringify({}));
            }
        });
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="container">
                    <NavbarIsi step={4} />
                    <LoadingFull display={this.state.loading} />

                    <div className="box-profile">
                        <h5>Silahkan Isi Info Pribadi Kamu.</h5>

                        <HrCustom icon="face_man" />

                        <div className="isi-pp mt-4">
                            <div className="form-group">
                                <label htmlFor="">Nama Lengkap</label>
                                <input type="text" className="form-control" onChange={this.handleNama} value={this.state.data.nama} placeholder="Masukan Nama Lengkap Kamu" />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">Nomor Telepon</label>
                                <input type="text" className="form-control" onChange={this.handleTelp} value={this.state.data.no_telp} placeholder="Masukan Nomor Telepon Kamu" />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">Tanggal Lahir</label>
                                <input type="date" className="form-control" onChange={this.handleTtl} value={this.state.data.ttl} placeholder="Masukan Nomor Telepon Kamu" />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">Jenis Kelamin</label>
                                <select name="" className="form-control" placeholder="Masukan Jurusan Kamu" id="" onChange={this.handleJk}>
                                    <option value={this.state.data.jk}>{this.state.data.jk === "L" ? "Laki - Laki" : "Perempuan"}</option>
                                    <option value="L">Laki - Laki</option>
                                    <option value="P">Perempuan</option>
                                </select>
                            </div>

                            <div className="form-group mt-3">
                                <label htmlFor="">Alamat</label>
                                <textarea name="" onChange={this.handleAlamat} className="form-control form-text-area" value={this.state.data.alamat} id="" cols={50} rows={10}></textarea>
                            </div>
                        </div>

                    </div>
                </div>

                <FooterButton openModal={this.openModal} disabled={this.state.disabled} to_2="/step3" btn_second="Kembali" simpan={true} />

                <ModalConfirm isOpen={this.state.isOpen} closeModal={this.closeModal} title="Apakah kamu yakin ingin menyimpan profile ini?" message={<><p>Setelah meng-klik tombol “SIMPAN” data yang kamu ubah tidak bisa kembali lagi.</p></>} status="warning" handleSimpan={this.handleSimpan} />
            </>
        )
    }
}

export default withRouter(Step4);