import React from "react";
import { Link } from "react-router-dom";

class NavbarBack extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="navbar-back">
                    <div className="navbar-back-content">
                        <div className="d-flex">
                            <div>
                                <Link to={this.props.to}><img src="/images/back_sidebar.png" alt="" /></Link>
                            </div>
                            <div className="align-self-center ps-3">
                                <div className="navbar-back-title">
                                    <h5 className="text-blacks mb-0">Edit Profile</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NavbarBack;