import React from "react";
import Etc from "../../module/Etc";

class ResultValuePolagram extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            line_1: [],
            line_2: [],
            line_3: [],
            line_4: [],
            line_5: [],
            loading: true,
        }
    }

    getValue() {

        const data = this.props.data;


        const line = [];
        const line_1 = data.data.slice(0, 7)
        const line_2 = data.data.slice(7, 14)
        const line_3 = data.data.slice(14, 20)
        const line_4 = data.data.slice(20, 27)
        const line_5 = data.data.slice(27, 34)

        line.push(line_1, line_2, line_3, line_4, line_5)
        if (line[0].length !== 0) {
            const line_s_1 = line_1.map((el, i) => {
                return (
                    <>
                        <div className="item-value-polagram">
                            <div className={`value-polagram-header bg-${el.warna}`}>
                                <p>{el.singkatan}</p>
                            </div>

                            <div className={`value-polagram-body bg-${el.warna}-opacity`}>
                                <p>{el.score}</p>
                            </div>
                        </div>
                    </>
                )
            })

            const line_s_2 = line_2.map(el => {
                return (
                    <>
                        <div className="item-value-polagram">
                            <div className={`value-polagram-header bg-${el.warna}`}>
                                <p>{el.singkatan}</p>
                            </div>

                            <div className={`value-polagram-body bg-${el.warna}-opacity`}>
                                <p>{el.score}</p>
                            </div>
                        </div>
                    </>
                )
            });

            const line_s_3 = line_3.map(el => {
                return (
                    <>
                        <div className="item-value-polagram">
                            <div className={`value-polagram-header bg-${el.warna}`}>
                                <p>{el.singkatan}</p>
                            </div>

                            <div className={`value-polagram-body bg-${el.warna}-opacity`}>
                                <p>{el.score}</p>
                            </div>
                        </div>
                    </>
                )
            });

            const line_s_4 = line_4.map(el => {
                return (
                    <>
                        <div className="item-value-polagram">
                            <div className={`value-polagram-header bg-${el.warna}`}>
                                <p>{el.singkatan}</p>
                            </div>

                            <div className={`value-polagram-body bg-${el.warna}-opacity`}>
                                <p>{el.score}</p>
                            </div>
                        </div>
                    </>
                )
            });

            const line_s_5 = line_5.map(el => {
                return (
                    <>
                        <div className="item-value-polagram">
                            <div className={`value-polagram-header bg-${el.warna}`}>
                                <p>{el.singkatan}</p>
                            </div>

                            <div className={`value-polagram-body bg-${el.warna}-opacity`}>
                                <p>{el.score}</p>
                            </div>
                        </div>
                    </>
                )
            });

            this.setState({
                line_1: line_s_1,
                line_2: line_s_2,
                line_3: line_s_3,
                line_4: line_s_4,
                line_5: line_s_5,
                loading: false,
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.data !== this.props.data) {

            this.getValue();
        }
    }

    componentDidMount(): void {
    }

    render(): React.ReactNode {
        return (
            <>
                {
                    this.state.loading === true ? <>
                        <div className="text-center mt-5">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </> : <></>
                }
                <div className="value-result-polagram mt-3">
                    <div className="d-flex justify-content-center">
                        {this.state.line_1}
                    </div>


                    <div className="d-flex justify-content-center mt-3">
                        {this.state.line_2}
                    </div>


                    <div className="d-flex justify-content-center mt-3">
                        {this.state.line_3}
                    </div>


                    <div className="d-flex justify-content-center mt-3">
                        {this.state.line_4}
                    </div>


                    <div className="d-flex justify-content-center mt-3">
                        {this.state.line_5}
                    </div>
                </div>
            </>
        )
    }
}

export default ResultValuePolagram;