import React from "react";

class SoalPolagram extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            checked: "",
            total_soal: 0,
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        this.setState({
            checked: e.target.getAttribute("data-value")
        });


        const answer = [];
        const getanswer = localStorage.getItem("answer");
        const arr_empty = JSON.parse(localStorage.getItem("arr_empty"));
        // [[id_pertanyaan, id_jawaban]]

        const checked_value = parseInt(e.target.getAttribute("data-value"));
        const pastanswer = JSON.parse(getanswer);
        const soal_id = [];




        // get id_pertanyaan
        pastanswer.forEach(el => {
            soal_id.push(el[0]);
        });

        if (pastanswer.length === 0) {
            pastanswer.push([this.props.data_soal.id_pertanyaan, checked_value]);
            arr_empty.push([this.props.data_soal.id_pertanyaan, checked_value]);
        } else {
            const filter_answer = soal_id.some((el) => el === this.props.data_soal.id_pertanyaan);

            if (filter_answer) {
                pastanswer.map(el1 => {
                    if (el1[0] === this.props.data_soal.id_pertanyaan) {

                        el1[1] = checked_value;
                    }
                });
            } else {
                pastanswer.push([this.props.data_soal.id_pertanyaan, checked_value]);
                arr_empty.push([this.props.data_soal.id_pertanyaan, checked_value]);
            }
            // set

        }
        localStorage.setItem("answer", JSON.stringify(pastanswer));
        localStorage.setItem("arr_empty", JSON.stringify(arr_empty))
        this.props.handleDisabled(false);
        // const getChecked = (e.target.getAttribute("aria-checked") === 'true');
        // e.target.setAttribute("aria-checked", !getChecked)
    }

    componentDidMount(): void {
        const get_pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));
        this.setState({
            total_soal: get_pagination.jumlah_soal,
        });

        this.props.handleDisabled(true);
        if (this.props.answer[1] !== null) {
            if (this.props.data_soal.id_pertanyaan === this.props.answer[0]) {
                this.setState({
                    checked: this.props.answer[1].toString()
                });
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.data_soal !== this.props.data_soal) {
            this.props.handleDisabled(true);

        }
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="soal-polagram mt-3">
                    <div className="soal-polagram-content">
                        <small>Pertanyaan {this.props.no_soal} dari {this.state.total_soal}</small>
                        <p>{this.props.data_soal.soal}</p>

                        <div className="soal-polagram-choose mb-3" role="radiogroup">
                            <div className="polagram-choose-item check-green" role="radio"
                                aria-checked={this.state.checked === this.props.data_soal.Option[0].id_jawaban.toString()}
                                tabIndex={0}
                                onClick={this.handleClick}
                                aria-labelledby="ariaLabel"
                                data-value={this.props.data_soal.Option[0].id_jawaban}>
                            </div>

                            <div className="polagram-choose-item check-green" role="radio"
                                aria-checked={this.state.checked === this.props.data_soal.Option[1].id_jawaban.toString()}
                                tabIndex={0}
                                onClick={this.handleClick}
                                aria-labelledby="ariaLabel"
                                data-value={this.props.data_soal.Option[1].id_jawaban}>
                            </div>

                            <div className="polagram-choose-item check-green" role="radio"
                                aria-checked={this.state.checked === this.props.data_soal.Option[2].id_jawaban.toString()}
                                tabIndex={0}
                                onClick={this.handleClick}
                                aria-labelledby="ariaLabel"
                                data-value={this.props.data_soal.Option[2].id_jawaban}>
                            </div>

                            <div className="polagram-choose-item check-abu" role="radio"
                                aria-checked={this.state.checked === this.props.data_soal.Option[3].id_jawaban.toString()}
                                tabIndex={0}
                                onClick={this.handleClick}
                                aria-labelledby="ariaLabel"
                                data-value={this.props.data_soal.Option[3].id_jawaban}>
                            </div>

                            <div className="polagram-choose-item check-purple" role="radio"
                                aria-checked={this.state.checked === this.props.data_soal.Option[4].id_jawaban.toString()}
                                tabIndex={0}
                                onClick={this.handleClick}
                                aria-labelledby="ariaLabel"
                                data-value={this.props.data_soal.Option[4].id_jawaban}>
                            </div>

                            <div className="polagram-choose-item check-purple" role="radio"
                                aria-checked={this.state.checked === this.props.data_soal.Option[5].id_jawaban.toString()}
                                tabIndex={0}
                                onClick={this.handleClick}
                                aria-labelledby="ariaLabel"
                                data-value={this.props.data_soal.Option[5].id_jawaban}>
                            </div>

                            <div className="polagram-choose-item check-purple" role="radio"
                                aria-checked={this.state.checked === this.props.data_soal.Option[6].id_jawaban.toString()}
                                tabIndex={0}
                                onClick={this.handleClick}
                                aria-labelledby="ariaLabel"
                                data-value={this.props.data_soal.Option[6].id_jawaban}>
                            </div>

                        </div>

                    </div>

                    <div className="soal-polagram-ket">
                        <div className="d-flex">
                            <div className="w-100 text-start">
                                <p className=" text-blue">SETUJU</p>
                            </div>
                            <div className="w-100 text-center">
                                <p className=" text-grey">NETRAL</p>
                            </div>
                            <div className="w-100 text-end">
                                <p className=" text-purple">TIDAK</p>
                            </div>
                        </div>
                    </div>

                    <div className="hr-custom"></div>
                </div>
            </>
        )
    }
}

export default SoalPolagram;