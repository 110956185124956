import React from "react";

class NavbarHome extends React.Component<any, any> {
    constructor(props) {
        super(props);

    }

    render(): React.ReactNode {
        return (
            <>
                <div className="navbar-home">
                    <div className="d-flex">
                        <div className="img-isi-pp-2">
                            <img src={this.props.img} alt="" />
                        </div>
                        <div className="flex-grow-1 ps-3">
                            <div className="title-navbar-home">
                                <span>Hai, Selamat Datang </span>
                                <p>{this.props.nama}</p>
                            </div>
                        </div>
                        <div onClick={this.props.openSidebar}>
                            <img src="./images/sidebar_icon.png" alt="" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NavbarHome;