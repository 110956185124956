import React from "react";

class NavbarIsi extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="navbar-isi">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <div className="navbar-isi-title">
                                <span>Sebelum lanjut, ayo</span>
                                <p>Lengkapi Profile Kamu</p>
                            </div>
                        </div>

                        <div className="align-self-center">
                            <span className="badge rounded-pill text-bg-warnings">Step <span className="text-extrabold">{this.props.step} of 4</span></span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NavbarIsi;