import axios from "./axios";

class Assesment {
    static async getAssesment(data_auth) {
        const auth:any = JSON.parse(data_auth);
        return new Promise((res, rej) => {
            axios({
                method: "GET",
                url: "/api/siswa/assesment",
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,                
                }
            }).then((result) => {
                
                if (result.data.data.status.code !== 202) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static getDetail(data_auth, data) {
        const auth:any = JSON.parse(data_auth);
        return new Promise((res, rej) => {
            axios({
                method: "POST",
                url: "/api/siswa/assesment/detail",
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,
                    "Content-Type": "multipart/form-data"           
                },
                data: data
            }).then((result) => {
                
                if (result.data.data.status.code !== 202) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static getSoal(data_auth, data) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            axios({
                method: "POST",
                url: `/api/siswa/assesment/ambil_soal?page=${data.page}`,
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*"
                },
                data:data.data
            }).then((result) => {
                
                if (result.data.data.status.code === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static sendAnswer(data_auth, data) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            axios({
                method: "POST",
                url: `/api/siswa/assesment/kirim_jawaban`,
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,
                    "Access-Control-Allow-Origin": "*"
                },
                data:data
            }).then((result) => {
                
                if (result.data.data.status.code === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async getHasil(data_auth, data) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            axios({
                method: "POST",
                url: `/api/siswa/assesment/hasil_test`,
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,
                    "Access-Control-Allow-Origin": "*"
                },
                data:data
            }).then((result) => {
                
                if (result.data.data.status.code === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }
}

export default Assesment;