import React from 'react';
import { Button } from 'react-bootstrap';
import Copyright from './etc/Copyright';
import Helmet from 'react-helmet';
import AlertBottom from './etc/AlertBottom';
import Auth from './module/Auth';
import Loading from './etc/Loading';
import { Response } from './Interface';
import Etc from './module/Etc';
import { Navigate } from 'react-router-dom';

class Login extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",

            msg_username: "",
            msg_password: "",

            disabled: true,
            loading: false,

            isLogged: false,
            navigation: false,
        }

        this.handleUsername = this.handleUsername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.clearState = this.clearState.bind(this);
    }

    componentDidMount(): void {
        const logged = JSON.parse(localStorage.getItem("logged-rapidtes"));
        if (logged === false || logged === null) {
            return;
        } else {
            this.setState({
                navigation: true,
            })
        }
    }

    validated() {
        setTimeout(() => {
            if (Etc.isObjectEmpty({ username: this.state.username, password: this.state.password })) {
                this.setState({ disabled: false });
            } else {
                this.setState({ disabled: true });
            }
        }, 1000)
    }

    handleUsername(e) {
        this.setState({
            username: e.target.value,
        });
        this.validated();
        if (e.target.value.length === 0) {
            this.setState({
                msg_username: <AlertBottom show={true} msg="Username tidak boleh kosong." />,
            })
        } else {
            this.setState({
                msg_username: "",
            })
        }
    }

    handlePassword(e) {
        this.setState({
            password: e.target.value,
        });
        this.validated();
        if (e.target.value.length === 0) {
            this.setState({
                msg_password: <AlertBottom show={true} msg="Password tidak boleh kosong." />,
            })
        } else {
            this.setState({
                msg_password: "",
            })
        }
    }

    clearState() {
        this.setState({
            username: "",
            password: "",
        });
        this.validated();
    }

    handleLogin() {
        const data = {
            user: this.state.username,
            password: this.state.password,
            log: 2
        }

        this.setState({
            disabled: true,
            loading: true,
        });

        Auth.sendLogin(data).then((result: any) => {

            this.setState({
                disabled: false,
                loading: false,
            });

            if (result.response === true) {

                this.setState({
                    isLogged: <Navigate to="/dashboard" replace={true} />
                })
                localStorage.setItem("user-rapidtest", JSON.stringify(result.data.result));
                localStorage.setItem("logged-rapidtes", JSON.stringify(true));
            } else {
                this.clearState();
                this.setState({
                    msg_password: <AlertBottom show={true} msg="Username atau Password salah." />,
                })
            }
        });
    }

    render(): React.ReactNode {
        return (
            <>
                {this.state.navigation !== false && <Navigate to="/dashboard" replace={true} />}

                <Helmet>
                    <title>Login | Ambisius Meter</title>
                </Helmet>

                {this.state.isLogged}
                <div className='container'>
                    <div className='me-3 ms-3'>
                        <div className='logo-img'>
                            <img style={{ width: "256px" }}  src="./images/logo.png" alt="" />
                        </div>

                        <div className='mt-5'>
                            <h3 className='text-bold pt-20'>Hai, Selamat Datang di</h3>
                            <h1 className='text-uppercase text-blacks text-blue'>Ambisius Meter</h1>
                            <p>Silahkan login untuk mulai menggunakan aplikasi!</p>
                        </div>

                        <div className='hr-blue mb-3 w-25'></div>
                    </div>

                    <div className='box-blue mt-5'>
                        <div className='box-blue-content'>
                            <div className='form-group'>
                                <label htmlFor="">Email/Username</label>
                                <input type="text" onChange={this.handleUsername} value={this.state.username} className='form-control' />
                                {this.state.msg_username}
                            </div>

                            <div className='form-group mt-2'>
                                <label htmlFor="">Password</label>
                                <input type="password" onChange={this.handlePassword} value={this.state.password} className='form-control' />
                                {this.state.msg_password}
                            </div>

                            <div className='form-group' style={{ marginTop: 40 }}>
                                <div className='d-grid'>
                                    <Button className='btn btn-primary text-uppercase' onClick={this.handleLogin} disabled={this.state.disabled}>Login <Loading show={this.state.loading} /></Button>
                                </div>
                            </div>

                            <div className='form-group text-center mt-4'>
                                <p className='text-muted text-12'>Lupa Password? <a href="/lupa_password" className='text-primarys text-uppercase text-extrabold'>Klik disini</a></p>
                            </div>
                        </div>
                    </div>
                    <Copyright />

                </div>
            </>
        )
    }
}

export default Login;