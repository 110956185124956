import React from "react";
import Auth from "../module/Auth";

class InfoSekolah extends React.Component<any, any> {
    constructor(props) {
        super(props);

    }



    render(): React.ReactNode {
        return (
            <>
                <ul className="list-group">
                    <li className="list-group-item mb-2">
                        <div className="d-flex">
                            <div className="align-self-center pe-2">
                                <img src="./images/icon_name.png" alt="" />
                            </div>
                            <div>
                                <div className="profile-list-content">
                                    <span className={this.props.pt_12}>Nama</span>
                                    <p className={this.props.pt_14}>{this.props.data_user.nama}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item mb-2">
                        <div className="d-flex">
                            <div className="align-self-center pe-2">
                                <img src="./images/icon_class.png" alt="" />
                            </div>
                            <div>
                                <div className="profile-list-content">
                                    <span className={this.props.pt_12}>Kelas</span>
                                    <p className={this.props.pt_14}>{this.props.data_user.kelas}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item mb-2">
                        <div className="d-flex">
                            <div className="align-self-center pe-2">
                                <img src="./images/icon_faculty.png" alt="" />
                            </div>
                            <div>
                                <div className="profile-list-content">
                                    <span className={this.props.pt_12}>Jurusan</span>
                                    <p className={this.props.pt_14}>{this.props.data_user.jurusan}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="d-flex">
                            <div className="align-self-center pe-2">
                                <img src="./images/icon_school.png" alt="" />
                            </div>
                            <div>
                                <div className="profile-list-content">
                                    <span className={this.props.pt_12}>Sekolah</span>
                                    <p className={this.props.pt_14}>{this.props.data_user.sekolah}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </>
        )
    }
}

export default InfoSekolah;