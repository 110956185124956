import React from "react";
import { Link } from "react-router-dom";

class CardRapid extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            desc: "",
            to: "",
            to_hasil: "",
        }
    }

    getKategori() {
        if (this.props.title === "Polagram") {
            this.setState({
                desc: "Asesmen Minat merupakan rangkaian asesmen dan analisa yg menggambarkan kemampuan kognitif, karakteristik minat bakat serta . . . . .",
                to: "/tes/polagram",
                to_hasil: "/tes/polagram/result"
            });
        } else if (this.props.title === "Tes Gaya Belajar") {
            this.setState({
                desc: "Asesmen Tipe Gaya Belajar adalah cara seseorang merasa mudah, nyaman, dan aman saat belajar, baik dari sisi waktu maupun secara indra. Gaya . . . . .",
                to: "/tes/gaya_belajar",
                to_hasil: "/tes/gaya_belajar/result"
            });
        } else if (this.props.title === "RIASEC") {
            this.setState({
                desc: "Asesmen Peminatan SDS Holland merupakan pendekatan terhadap penaksiran minat-minat pekerjaan. Sebuah asesmen yang didasarkan pada teori . . . . .",
                to: "/tes/riasec",
                to_hasil: "/tes/riasec/result"
            });
        } else if (this.props.title === "Kepribadian") {
            this.setState({
                desc: "Asesmen Kepribadian merupakan asesmen untuk mendeskripsikan bagaimana kecenderungan seseorang bertingkah laku. Asesmen kepribadian . . . .",
                to: "/tes/kepribadian",
                to_hasil: "/tes/kepribadian/result"
            });
        } else if (this.props.title === "IQ") {
            this.setState({
                desc: "Lorem ipsum dolor sit amet consectetur. Velit massa vulputate sit justo commodo in feugiat hendrerit. Sapien ornare nisl quam eget dui diam aliquet . . . . .",
                to: "/tes/iq/subtest",
                to_hasil: "/tes/iq/result"
            })
        }
    }

    componentDidMount(): void {
        this.getKategori();
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.props.title !== prevProps.title) {
            this.getKategori();
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="card h-100">
                    <div className={`card-img ${this.props.img}`}>
                        <img src={`./images/${this.props.img}.png`} alt="..." />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{this.props.title}</h5>
                        <div className="d-grid">
                            {
                                this.props.pengerjaan ? <Link to={this.state.to_hasil} state={{ title: this.props.title, img: this.props.img, message: this.state.desc, to: this.state.to, data: this.props.data }} className="btn btn-info btn-sm pt-2 pb-2 ps-4 mt-2 pe-4">HASIL</Link> : <Link to="/detail_tes" state={{ title: this.props.title, img: this.props.img, message: this.state.desc, to: this.state.to, data: this.props.data }} className="btn btn-primary btn-sm pt-2 pb-2 ps-4 mt-2 pe-4">MULAI</Link>
                            }

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CardRapid;