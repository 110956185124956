const data_kategori = {
    line_1: [
        {
            name: "Futuristic",
            warna: "",
        },
        {
            name: "Strategic",
            warna: "",
        },
        {
            name: "Ideation",
            warna: "",
        },
        {
            name: "Analytical",
            warna: "",
        },
        {
            name: "Learner",
            warna: "",
        },
        {
            name: "Intellection",
            warna: "",
        },
        {
            name: "Input",
            warna: "",
        },
        {
            name: "Context",
            warna: "",
        }
    ],

    line_2: [
        {
            name: "Command",
            warna: "",
        },
        {
            name: "Activator",
            warna: "",
        },
        {
            name: "Competition",
            warna: "",
        },
        {
            name: "Maximizer",
            warna: "",
        },
        {
            name: "Significance",
            warna: "",
        },
        {
            name: "Self Assurance",
            warna: "",
        },
        {
            name: "Communication",
            warna: "",
        },
        {
            name: "WOO",
            warna: "",
        }
    ],

    line_3: [
        {
            name: "Restorative",
            warna: "",
        },
        {
            name: "Deliberative",
            warna: "",
        },
        {
            name: "Arranger",
            warna: "",
        },
        {
            name: "Discipline",
            warna: "",
        },
        {
            name: "Consistency",
            warna: "",
        },
        {
            name: "Focus",
            warna: "",
        },
        {
            name: "Achiever",
            warna: "",
        },
        {
            name: "Responsibility",
            warna: "",
        },
        {
            name: "Belief",
            warna: "",
        }
    ],

    line_4: [
        {
            name: "Positivity",
            warna: "",
        },
        {
            name: "Developer",
            warna: "",
        },
        {
            name: "Relator",
            warna: "",
        },
        {
            name: "Includer",
            warna: "",
        },
        {
            name: "Harmony",
            warna: "",
        },
        {
            name: "Adaptability",
            warna: "",
        },
        {
            name: "Individualization",
            warna: "",
        },
        {
            name: "Connectedness",
            warna: "",
        },
        {
            name: "Empathy",
            warna: "",
        }
    ]
}

export default data_kategori;