import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './component/Login';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import LupaPassword from './component/LupaPassword';
import Verifikasi from './component/Verifikasi';
import GantiPassword from './component/GantiPassword';
import IsiProfile from './component/isi_profile/IsiProfile';
import Step1 from './component/isi_profile/Step1';
import Step2 from './component/isi_profile/Step2';
import Step3 from './component/isi_profile/Step3';
import Step4 from './component/isi_profile/Step4';
import Dashboard from './component/dashboard/Dashboard';
import Profile from './component/profile/Profile';
import EditProfile from './component/profile/EditProfile';
import RapidTes from './component/rapidtes/RapidTes';
import DetailTes from './component/rapidtes/DetailTes';
import TesPolagram from './component/tes/polagram/TesPolagram';
import ResultPolagram from './component/tes/polagram/ResultPolagram';
import TesKepribadian from './component/tes/kepribadian/TesKepribadian';
import ResultKepribadian from './component/tes/kepribadian/ResultKepribadian';
import TesGayaBelajar from './component/tes/gaya_belajar/TesGayaBelajar';
import ResultGayaBelajar from './component/tes/gaya_belajar/ResultGayaBelajar';
import TesRiasec from './component/tes/riasec/TesRiasec';
import ResultRiasec from './component/tes/riasec/ResultRiasec';
import TesIqSoal from './component/tes/iq/TesIqSoal';
import AuthCheck from './component/AuthCheck';
import Logout from './component/etc/Logout';
import SubtestIq from './component/tes/iq/SubtestIq';
import ResultIq from './component/tes/iq/ResultIq';
import RedirectIfAuthenticated from './component/RedirectIfAuthenticated';

function App() {
  return (
    <Routes>
      
      
      <Route 
        path="/" 
        element={
            <RedirectIfAuthenticated>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/lupa_password' element={<LupaPassword />} />
                    <Route path='/verifikasi' element={<Verifikasi />} />
                    <Route path='/ganti_password' element={<GantiPassword />} />
                </Routes>
            </RedirectIfAuthenticated>
        }
    />

        <Route path='/step1' element={<Step1 />}></Route>
        <Route path='/step2' element={<Step2 />}></Route>
        <Route path='/step3' element={<Step3 />}></Route>
        <Route path='/step4' element={<Step4 />}></Route>


      <Route element={<AuthCheck />}>

        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path='/profile' element={<Profile />}></Route>
        <Route path='/edit_profile' element={<EditProfile />}></Route>

        <Route path='/asesmen' element={<RapidTes />}></Route>
        <Route path='/detail_tes' element={<DetailTes />}></Route>

        <Route path='/tes/polagram' element={<TesPolagram />}></Route>
        <Route path='/tes/polagram/result' element={<ResultPolagram />}></Route>

        <Route path='/tes/kepribadian' element={<TesKepribadian />}></Route>
        <Route path='/tes/kepribadian/result' element={<ResultKepribadian />}></Route>

        <Route path='/tes/gaya_belajar' element={<TesGayaBelajar />}></Route>
        <Route path='/tes/gaya_belajar/result' element={<ResultGayaBelajar />}></Route>

        <Route path='/tes/riasec' element={<TesRiasec />}></Route>
        <Route path='/tes/riasec/result' element={<ResultRiasec />}></Route>

        <Route path='/tes/iq/subtest' element={<SubtestIq />}></Route>
        <Route path='/tes/iq' element={<TesIqSoal />}></Route>
        <Route path='/tes/iq/result' element={<ResultIq />}></Route>

        <Route path='/logout' element={<Logout />}></Route>
      </Route>
    </Routes>
  );
}

export default App;
