import React from "react";
import Auth from "../module/Auth";
import { Navigate } from "react-router-dom";

class Logout extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            navigation: false,
        }
    }

    componentDidMount(): void {
        const data_auth = localStorage.getItem("user-rapidtest");
        localStorage.setItem("logged-rapidtes", "false");
        Auth.sendLogout(data_auth).then((result: any) => {
            this.setState({
                navigation: <Navigate to={'/'} replace={true} />
            });
        })
    }

    render(): React.ReactNode {
        return (
            <>
                {
                    this.state.navigation
                }
            </>
        )
    }
}

export default Logout;