import React from "react";
import NavbarTes from "../NavbarTes";
import NavbarTime from "./NavbarTime";
import SoalIq from "./SoalIq";
import ModalShowPicture from "../../modal/ModalShowPicture";
import ModalResult from "../../modal/ModalResult";
import { withRouter } from "../../etc/withRouter";
import SoalPg from "./SoalPg";
import SoalEssay from "./SoalEssay";
import ModalIq from "../../modal/ModalIq";

class TesIqSoal extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isOpen_Result: false,
            isOpen_Lanjut: false,
            timer: [],
            component_soal: [],
            hal: 0,
            max_hal: 0,
            timer_gambar: [],
            time_func: {},
            disabled: true,
        }

        this.openImage = this.openImage.bind(this);
        this.closeImage = this.closeImage.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModalLanjut = this.openModalLanjut.bind(this);
        this.closeModalLanjut = this.closeModalLanjut.bind(this);
        this.handleDisabled = this.handleDisabled.bind(this);
    }

    handleDisabled(dsb?) {
        const get_empty = JSON.parse(localStorage.getItem("arr_empty"));
        if (get_empty.length !== this.state.component_soal.length) {
            this.setState({
                disabled: true,
            })
        } else {
            this.setState({
                disabled: false,
            })
        }
    }

    timer(duration: any) {
        if (duration !== 0) {
            var timer: any = duration, minutes: any, seconds: any;
            const timers = setInterval(() => {

                minutes = parseInt((timer / 60).toString());
                seconds = parseFloat((timer % 60).toString());

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                const data_t = [...String(minutes), ...String(seconds)].map(Number);

                this.setState({
                    timer: data_t,
                });

                const timer_now = parseFloat(minutes) + (seconds / 60);
                localStorage.setItem('timer', timer_now.toString());


                if (--timer < 0) {
                    if (this.state.hal === this.state.max_hal) {
                        this.openModal();
                    } else {
                        this.openModalLanjut();
                    }
                    timer = 0;
                    this.setState({
                        timer: [0, 0, 0, 0]
                    });
                    localStorage.setItem("timer", "0");
                    clearInterval(timers)
                }
            }, 1000);
            this.setState({
                time_func: timers
            })
            return timers;
        } else {
            this.setState({
                timer: [0, 0, 0, 0]
            });

            localStorage.setItem("timer", "0");
            if (this.state.hal === this.state.max_hal) {
                this.openModal();
            } else {
                this.openModalLanjut();
            }
        }
    }

    getTime() {

        const timer = localStorage.getItem("timer");
        this.timer(parseInt(timer) * 60);
    }

    getSoal() {
        const data_soal = this.props.location.state.data_soal;
        const getanswer = JSON.parse(localStorage.getItem("answer"));
        const get_halaman = JSON.parse(localStorage.getItem("current_soal"));
        const pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"))
        var id_soal = [];
        getanswer.forEach(el => {
            id_soal.push({ id_pertanyaan: el[0], id_jawaban: el[1] });
        });
        var comp_soal;
        // 1-3 PG biasa, 4 essai, 5-6 PG biasa, 7-8 PG gambar, 9 PG biasa
        var no = 1;
        if (get_halaman[2] === 1 || get_halaman[2] === 2 || get_halaman[2] === 3 || get_halaman[2] === 5 || get_halaman[2] === 6 || get_halaman[2] === 9) {
            comp_soal = data_soal.map((el, i) => {
                var answer = null;

                const filter = id_soal.filter(item => item.id_pertanyaan === el.id_pertanyaan);

                if (filter.length !== 0) {
                    answer = [filter[0].id_pertanyaan, filter[0].id_jawaban]
                } else {
                    answer = [el.id_pertanyaan, null]
                }
                return (
                    <SoalPg data_soal={el} no_soal={no++} hal={get_halaman[2]} handleDisabled={this.handleDisabled} answer={answer} key={i} />
                )
            });
        }

        if (get_halaman[2] === 4) {

            comp_soal = data_soal.map((el) => {
                var answer = null;

                const filter = id_soal.filter(item => item.id_pertanyaan === el.id_pertanyaan);

                if (filter.length !== 0) {
                    answer = [filter[0].id_pertanyaan, filter[0].id_jawaban]
                } else {
                    answer = [el.id_pertanyaan, null]
                }
                return (
                    <SoalEssay data_soal={el} no_soal={no++} hal={get_halaman[2]} handleDisabled={this.handleDisabled} answer={answer} />
                )
            });
        }

        if (get_halaman[2] === 7 || get_halaman[2] === 8) {
            var no = 1;
            comp_soal = data_soal.map((el, i) => {
                var answer = null;

                const filter = id_soal.filter(item => item.id_pertanyaan === el.id_pertanyaan);

                if (filter.length !== 0) {
                    answer = [filter[0].id_pertanyaan, filter[0].id_jawaban]
                } else {
                    answer = [el.id_pertanyaan, null]
                }
                return (
                    <SoalIq data_soal={el} no_soal={no++} hal={get_halaman[2]} handleDisabled={this.handleDisabled} answer={answer} key={i} />
                )
            })
        }
        this.setState({
            component_soal: comp_soal,
            hal: get_halaman[2],
            max_hal: pagination.jumlah
        })
    }

    timerPicture(duration) {
        if (duration !== 0) {
            var timer: any = duration, minutes: any, seconds: any;
            const timers = setInterval(() => {

                minutes = parseInt((timer / 60).toString());
                seconds = parseFloat((timer % 60).toString());

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                const data_t = [...String(minutes), ...String(seconds)].map(Number);

                this.setState({
                    timer_gambar: data_t,
                });

                const timer_now = parseFloat(minutes) + (seconds / 60);
                localStorage.setItem('timer_gambar', timer_now.toString());

                if (--timer < 0) {
                    timer = 0;
                    this.setState({
                        timer_gambar: [0, 0, 0, 0]
                    });
                    localStorage.setItem("timer_gambar", "0");
                    clearInterval(timers);
                    this.closeImage();
                }
            }, 1000);
            return timers;
        } else {
            this.setState({
                timer_gambar: [0, 0, 0, 0]
            });
            localStorage.setItem("timer_gambar", "0");
            setTimeout(() => {
                this.closeImage();
            }, 1000)

        }
    }

    getTimerPicture() {
        const timer = localStorage.getItem("timer_gambar");
        this.timerPicture(parseInt(timer) * 60);
    }

    componentDidMount(): void {
        const get_halaman = JSON.parse(localStorage.getItem("current_soal"));
        const pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));

        if (get_halaman[2] === pagination.jumlah) {
            this.getTimerPicture();
            this.openImage();
        } else {
            this.getTime();
        }

        this.getSoal();

    }

    openImage() {
        this.setState({
            isOpen: true,
        });
    }

    closeImage() {
        this.setState({
            isOpen: false,
        });

        this.getTime();
    }

    openModal() {
        this.setState({
            isOpen_Result: true,
        });
    }

    closeModal() {
        this.setState({
            isOpen_Result: false,
        })
    }

    openModalLanjut() {
        this.setState({
            isOpen_Lanjut: true,
        });
    }

    closeModalLanjut() {
        this.setState({
            isOpen_Lanjut: false,
        })
    }

    // componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event) {
    //         window.history.pushState(null, document.title, window.location.href);
    //     });
    // }



    render(): React.ReactNode {
        return (
            <>
                <NavbarTes title="IQ" />
                <NavbarTime openModal={this.openImage} hal={this.state.hal} timer={this.state.timer} />
                <div className="container">
                    {
                        this.state.component_soal
                    }

                    <div className="d-grid mt-4 me-5 ms-5">
                        <button onClick={this.state.max_hal === this.state.hal ? this.openModal : this.openModalLanjut} className="btn btn-primary">SELESAI</button>
                    </div>
                </div>
                <ModalShowPicture isOpen={this.state.isOpen} gambar={this.props.location.state.data_subtest.gambar_halaman} timer={this.state.timer_gambar} closeModal={this.closeImage} />
                <ModalResult isLoading={this.state.loading} onLoadingStart={() => this.setState({ loading: true })}  isOpen={this.state.isOpen_Result} closeModal={this.closeModal} to="/tes/iq/result" title="IQ" />
                <ModalIq isOpen={this.state.isOpen_Lanjut} time_func={this.state.time_func} closeModal={this.closeModalLanjut} to="/tes/iq/subtest" title={this.state.hal} />

            </>
        )
    }
}

export default withRouter(TesIqSoal);