import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileModule from "../module/Profile";
import Loading from "../etc/Loading";

class ModalChangePp extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            img_choose: "/images/pp_1.png",
            comp_img: "",
            file_upload: "",
            auth: localStorage.getItem("user-rapidtest"),
            loading: false,
            disabled: false,
        }

        this.getImg = this.getImg.bind(this);
        this.getImgApi = this.getImgApi.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleUploadInput = this.handleUploadInput.bind(this);
    }

    handleUpload() {
        this.setState({
            loading: true,
            disabled: true,
        })
        if (this.state.file_upload.length === 0) {
            const data = {
                image: this.state.img_choose,
            }
            ProfileModule.changeDefaultProfile(this.state.auth, data).then((result: any) => {

                if (result.response === true) {
                    this.props.getProfile();
                    this.props.closeModal();
                    this.setState({
                        loading: false,
                        disabled: false,
                    })
                }
            })
        } else {
            const data = {
                avatar: this.state.file_upload,
            }

            ProfileModule.uploadImage(this.state.auth, data).then((result: any) => {

                if (result.response === true) {
                    this.props.getProfile();
                    this.props.closeModal();
                    this.setState({
                        loading: false,
                        disabled: false,
                    })
                }
            })
        }
    }

    getImg(e) {
        this.setState({
            img_choose: e.target.getAttribute('src'),
        });
    }

    getImgApi() {
        const data_auth = localStorage.getItem("user-rapidtest");

        ProfileModule.getDefaultProfile(data_auth).then((result: any) => {

            if (result.response === true) {
                const comp = result.data.result.map(el => {
                    return (
                        <>
                            <div className="isi-pp-item">
                                <img src={`${el.url}`} className={this.state.img_choose === `${el.url}` ? "active" : ""} onClick={this.getImg} alt="" />
                            </div>
                        </>
                    )
                });

                this.setState({
                    comp_img: comp,
                })
            }
        })
    }

    handleUploadInput(e) {
        this.setState({
            file_upload: e.target.files[0],
        })
    }

    componentDidMount(): void {
        this.getImgApi();
        this.setState({
            img_choose: this.props.img
        })
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.img !== this.props.img) {
            this.setState({
                img_choose: this.props.img
            })
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.props.isOpen} onHide={this.props.closeModal}>
                    <Modal.Body>
                        <div className="header-modal">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <img src="/images/modal_warning.png" alt="" />
                                </div>
                                <div onClick={this.props.closeModal}>
                                    <img src="/images/close_btn.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-title">
                            <p>Ganti Foto</p>
                        </div>
                        <div className="modal-message">
                            <div className="isi-pp mt-4">
                                <div className="substract-1">
                                    <img src="/images/circle_profile.png" alt="" />
                                </div>
                                <div className="substract-2">
                                    <img src="/images/circle_profile.png" alt="" />
                                </div>

                                <div className="text-center img-isi-pp">
                                    <img src={this.state.img_choose} alt="" />
                                </div>
                            </div>
                            <div className="isi-pp-catalogue">
                                <p>Silahkan pilih avatar berikut ini!</p>
                                <div className="isi-pp-content">
                                    {
                                        this.state.comp_img
                                    }
                                </div>
                            </div>

                            <div className="isi-profile-custom mt-3">
                                <p>Kamu juga bisa upload foto profilmu sendiri.</p>
                                <input type="file" name="file-input" onChange={this.handleUploadInput} className="file-input"></input>
                            </div>
                        </div>

                        <div className="mt-4 text-end">
                            <Button className="btn btn-primary text-uppercase ps-4 pe-4" disabled={this.state.disabled} onClick={this.handleUpload}>Simpan <Loading show={this.state.loading} /></Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ModalChangePp;