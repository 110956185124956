import React from "react";
import NavbarResult from "../NavbarResult";
import { Icon } from "@iconify/react";
import ResultValuePolagram from "./ResultValuePolagram";
import KeteranganPolagram from "./KeteranganPolagram";
import AccordionPolagram from "./AccordionPolagram";
import Copyright from "../../etc/Copyright";
import Helmet from 'react-helmet';
import { withRouter } from "../../etc/withRouter";
import Assesment from "../../module/Assesment";
import LoadingFull from "../../etc/LoadingFull";

class ResultPolagram extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            nama: "",
            get_auth: localStorage.getItem("user-rapidtest"),
            result_hasil: [],
            tgl: "",
            loading: true,
        }

        this.getHasil = this.getHasil.bind(this);

    }

    getHasil(get_auth) {
        const data = {
            id: this.props.location.state.data.id.toString(),
            name: this.props.location.state.data.name,
        }



        Assesment.getHasil(get_auth, data).then((result: any) => {

            if (result.response === true) {
                this.setState({
                    result_hasil: result.data.result,
                    tgl: result.data.result.tanggal_pengerjaan,
                    loading: false,
                });
            }
        })
    }

    componentDidMount(): void {
        const get_auth = JSON.parse(this.state.get_auth);
        this.getHasil(this.state.get_auth);

        this.setState({
            nama: get_auth.nama_lengkap,
        });

    }

    render(): React.ReactNode {
        return (
            <>
                <Helmet>
                    <title>Hasil Asesmen Polagram | Ambisius Meter</title>
                </Helmet>
                <NavbarResult />
                <LoadingFull display={this.state.loading} />
                <div className="container">
                    <div className="result-box">
                        <div className="result-title">
                            <h3>Hasil Asesmen Polagram,</h3>
                            <h5>{this.state.nama}</h5>
                        </div>

                        <div className="d-flex mt-4 mb-4">
                            <div className="align-self-center">
                                <Icon icon={"clarity:date-solid"} className="text-purple" style={{ position: "relative", top: -2, fontSize: 18 }} />
                            </div>

                            <div className="ps-2">
                                <p className="mb-0 text-extrabold text-purple">Dikerjakan Tanggal {this.state.tgl}</p>
                            </div>
                        </div>

                        <div className="hr-custom"></div>

                        <ResultValuePolagram data={this.state.result_hasil} />

                        <KeteranganPolagram data={this.state.result_hasil} />

                        <div className="mt-4">
                            <h3 className="text-blacks mb-3">Keterangan Warna</h3>
                            <ul className="list-group">
                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg bg-red"></div>
                                            <span>Merah</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">Polagram Sangat Dominan</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg bg-yellow"></div>
                                            <span>Kuning</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">Polagram Dominan</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg"></div>
                                            <span>Putih</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">Polagram Netral</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg bg-grey"></div>
                                            <span>Abu</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">Polagram Tidak Dominan</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg bg-darks"></div>
                                            <span>Hitam</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">Polagram Sangat Tidak Dominan</p>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>

                        <div className="mt-4">
                            <h3 className="text-blacks mb-3">Penjelasan Polagram</h3>

                            <AccordionPolagram data={this.state.result_hasil} />
                        </div>
                    </div>
                </div>

                <Copyright style={{ marginBottom: 40 }} />

            </>
        )
    }
}

export default withRouter(ResultPolagram);