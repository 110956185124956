import React from "react";
import Helmet from 'react-helmet';
import Copyright from "./etc/Copyright";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import OtpInput from "./etc/OtpInput";
import { withRouter } from "./etc/withRouter";
import Auth from "./module/Auth";
import AlertBottom from "./etc/AlertBottom";
import { Navigate } from "react-router-dom";

class Verifikasi extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            otp: [],
            disabled: true,
            loading: false,
            err: false,
            msg: "",
            navigation: "",
            email: this.props.location.state.email,
        }

        this.setOtp = this.setOtp.bind(this);
        this.setDisabled = this.setDisabled.bind(this);
        this.handleSend = this.handleSend.bind(this);
    }

    setDisabled(val) {
        this.setState({
            disabled: val,
        })
    }

    setOtp(val) {
        this.setState({
            otp: val
        });
    }

    handleSend() {
        const data = {
            email: this.state.email,
            otp: this.state.otp.join(""),
        }

        this.setState({
            loading: true,
            disabled: true,
        });

        Auth.sendForget(data).then((result: any) => {
            if (result.data.status.code !== 202) {
                localStorage.setItem("user-rapidtest", JSON.stringify(result.data.result));
                localStorage.setItem("logged-rapidtes", JSON.stringify(true));
                this.setState({
                    msg: "",
                    navigation: <Navigate to="/ganti_password" />,
                    disabled: true,
                    loading: false,
                });
            } else {
                this.setState({
                    msg: <AlertBottom show={true} msg="OTP tidak ditemukan." />,
                    disabled: true,
                    loading: false,
                });
            }
            console.log(result);
        });
    }

    render(): React.ReactNode {
        return (
            <>
                <Helmet>
                    <title>Verifikasi | Ambisius Meter</title>
                </Helmet>
                {this.state.navigation}
                <div className='container'>
                    <div className='me-3 ms-3'>
                        <div className='logo-img'>
                            <img style={{ width: "256px" }} src="./images/logo.png" alt="" />
                        </div>

                        <div className='mt-5'>
                            <h1 className='text-uppercase text-blacks text-blue'>VERIFIKASI</h1>
                            <p>Silahkan cek gmail kamu ({this.state.email}) untuk mendapatkan Kode OTP!</p>
                        </div>

                        <div className='hr-blue mb-3 w-25'></div>
                    </div>

                    <div className='box-blue mt-5'>
                        <div className='box-blue-content'>
                            <div className='form-group'>
                                <label htmlFor="">Masukan Kode OTP</label>
                                <div className="d-flex flex-row justify-content-center">
                                    <OtpInput setOtp={this.setOtp} setDisabled={this.setDisabled} />
                                </div>
                                {this.state.msg}
                            </div>


                            <div className='form-group' style={{ marginTop: 40 }}>
                                <div className='d-grid'>
                                    <Button className='btn btn-primary text-uppercase' disabled={this.state.disabled} onClick={this.handleSend}>selanjutnya</Button>
                                </div>
                            </div>

                            <div className='form-group text-center mt-4'>
                                <span className="text-muted"><Icon icon={"ph:arrow-u-up-left-bold"} /><a href="/" className="text-muted text-extrabold"> Kembali Ke Halaman Login</a></span>
                            </div>
                        </div>
                    </div>
                    <Copyright />

                </div>
            </>
        )
    }
}

export default withRouter(Verifikasi);