import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "../etc/withRouter";

class FooterButton extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="footer-button">
                    <div className="footer-button-content">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <Link to={this.props.to_2} className="btn-no-border btn-primary text-second btn no-underline pe-4 ps-4 pt-2 pb-2">{this.props.btn_second}</Link>
                            </div>
                            <div className="">
                                {
                                    this.props.disabled !== true ? <Link className="btn btn-primary pe-4 ps-4 pt-2 pb-2" to={this.props.to} state={this.props.state} onClick={this.props.openModal}>{this.props.simpan !== true ? "Lanjut" : "Simpan"}</Link> : <Link className="btn btn-primary disabled pe-4 ps-4 pt-2 pb-2" to={"#"}>{this.props.simpan !== true ? "Lanjut" : "Simpan"}</Link>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(FooterButton);