import { Icon } from "@iconify/react";
import React from "react";
import Copyright from "../../etc/Copyright";
import data_kategori from "./DataIq";

class KategoriIq extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            comp: "",
        }
    }

    sync(data) {
        data.data.map((el) => {
            if (el.nama !== "Kecerdasan Umum" || el.nama !== "Cara Berpikir" || el.nama !== "Fleksibilitas Berpikir") {
                data_kategori.map(el2 => {
                    if (el.nama === el2.nama) {
                        var warna;
                        if (el.keterangan === "Tinggi") {
                            warna = "bg-danger";
                        } else if (el.keterangan === "Cukup") {
                            warna = "bg-white";
                        } else if (el.keterangan === "Baik") {
                            warna = "bg-yellow";
                        } else if (el.keterangan === "Kurang") {
                            warna = "bg-grey";
                        } else if (el.keterangan === "Rendah") {
                            warna = "bg-black";
                        }

                        el2.warna = warna;
                        el2.keterangan = el.keterangan;
                    }
                });
            }
        })
    }

    getResult() {
        const data = this.props.data;
        this.sync(data);

        const comps = data_kategori.map(el => {
            return (
                <>
                    <div className="kategori-iq-item">
                        <div className="d-flex">
                            <div className="kategori-iq-icon me-3">
                                <Icon icon={el.icon} className="kategori-icon" />
                            </div>
                            <div className="align-self-center flex-grow-1">
                                <span>{el.nama}</span>
                            </div>
                            <div className="align-self-center">
                                <div className={`alert-iq ${el.warna}`}><p className="text-uppercase">{el.keterangan}</p></div>
                            </div>
                        </div>
                    </div>
                </>
            )
        });

        this.setState({
            comp: comps
        })
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.data !== this.props.data) {
            this.getResult();
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="kategori-iq">
                    {
                        this.state.comp
                    }
                </div>
            </>
        )
    }
}

export default KategoriIq;