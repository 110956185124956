import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../etc/withRouter";
import InfoSoal from "../../etc/InfoSoal";
import NavbarTes from "../NavbarTes";
import Assesment from "../../module/Assesment";
import LoadingFull from "../../etc/LoadingFull";

class SubtestIq extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            data_auth: localStorage.getItem("user-rapidtest"),
            data_subtest: {
                waktu: '',
                total_soal: '',
                intruksi_soal: '',
                contoh_pengerjaan: '',
                gambar_halaman: '',
                waktu_tampil_gambar: '',
                id_pengiriman: '',
                halaman_page: '',
            },
            soal: "",
            loading: true,
            curr: "",
        }

        this.getSubtest = this.getSubtest.bind(this);
        this.handleMulai = this.handleMulai.bind(this);
    }

    getSubtest() {
        const get_current = JSON.parse(localStorage.getItem("current_soal"));
        const data = {
            data: {
                id: get_current[0],
                name: get_current[1],
                halaman: get_current[2]
            },
            page: "",
        }

        Assesment.getSoal(this.state.data_auth, data).then((result: any) => {
            if (result.response === true) {
                const data_soal = [];
                result.data.result.map(el => {
                    if (el.id_pengiriman !== undefined) {
                        this.setState(prevState => ({
                            data_subtest: {
                                ...prevState.data_subtest,
                                waktu: el.waktu_pengerjaan,
                                total_soal: el.total_soal,
                                intruksi_soal: el.intruksi_halaman,
                                contoh_pengerjaan: el.contoh_pengerjaan,
                                gambar_halaman: el.gambar_halaman,
                                waktu_tampil_gambar: el.waktu_tampil_gambar,
                                id_pengiriman: el.id_pengiriman,
                                halaman_page: el.halaman_page,
                            }
                        }));
                    } else {
                        data_soal.push(el);
                    }

                });

                this.setState({
                    loading: false,
                    data_soal: data_soal,
                    curr: get_current[2]
                });
            }
        });
    }

    handleMulai() {
        localStorage.setItem("timer", this.state.data_subtest.waktu);
        localStorage.setItem("timer_gambar", this.state.data_subtest.waktu_tampil_gambar === null ? "0" : this.state.data_subtest.waktu_tampil_gambar);
        localStorage.setItem("pagination-rapidtest", JSON.stringify({ jumlah: this.state.data_subtest.halaman_page, jumlah_soal: this.state.data_subtest.total_soal, pengiriman: this.state.data_subtest.id_pengiriman }));
        localStorage.setItem("arr_empty", JSON.stringify([]))
    }


    componentDidMount(): void {
        this.getSubtest();
    }

    // componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event) {
    //         window.history.pushState(null, document.title, window.location.href);
    //     });
    // }

    render(): React.ReactNode {
        return (
            <>
                <NavbarTes title="IQ" warning={true} />
                <LoadingFull display={this.state.loading} />
                <div className="container">
                    <h5 className="text-blacks">Petunjuk pengerjaan & contoh soal IQ Subtest {this.state.curr}.</h5>
                    <div className="row mt-4">
                        <div className="col col-sm">
                            <div className="detail-info-box">
                                <div className="d-flex">
                                    <div className="align-self-center pe-2">
                                        <img src="/images/timer.png" alt="" />
                                    </div>
                                    <div>
                                        <span>Waktu</span>
                                        <p>{this.state.data_subtest.waktu === 0 ? "Tidak Diwaktu" : this.state.data_subtest.waktu + " Menit"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col col-sm">
                            <div className="detail-info-box">
                                <div className="d-flex">
                                    <div className="align-self-center pe-2">
                                        <img src="/images/question.png" alt="" />
                                    </div>
                                    <div>
                                        <span>Jumlah Soal</span>
                                        <p>{this.state.data_subtest.total_soal} Soal</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="keterangan-iq">
                        <div className="subtest-soal mt-4">
                            <div className="text-14" dangerouslySetInnerHTML={{ __html: this.state.data_subtest.intruksi_soal }}></div>

                            <div className="hr-custom mt-4 mb-4"></div>

                            <div className="contoh-pengerjaan text-14" dangerouslySetInnerHTML={{ __html: this.state.data_subtest.contoh_pengerjaan }}></div>

                            <div className="hr-custom mt-4 mb-4"></div>

                            <div className="d-grid mt-4 me-5 ms-5">
                                <Link to="/tes/iq" onClick={this.handleMulai} state={{ data_soal: this.state.data_soal, data_subtest: this.state.data_subtest }} className="btn btn-primary">MULAI</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(SubtestIq);