import React from "react";
import data_kategori from "./KategoriPolagram";


class KeteranganPolagram extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            line_1: [],
            line_2: [],
            line_3: [],
            line_4: [],
            loading: true,
        }
    }

    sync(data) {
        data.map((el) => {
            data_kategori.line_1.map(el2 => {
                if (el2.name === el.nama) {
                    el2.warna = el.warna
                }
            });

            data_kategori.line_2.map(el2 => {
                if (el2.name === el.nama) {
                    el2.warna = el.warna
                }
            });

            data_kategori.line_3.map(el2 => {
                if (el2.name === el.nama) {
                    el2.warna = el.warna
                }
            });

            data_kategori.line_4.map(el2 => {
                if (el2.name === el.nama) {
                    el2.warna = el.warna
                }
            });

        });

    }

    getValue() {
        const data = this.props.data;
        this.sync(data.data)

        if (data.data.length !== 0) {
            this.setState({
                line_1: data_kategori.line_1,
                line_2: data_kategori.line_2,
                line_3: data_kategori.line_3,
                line_4: data_kategori.line_4,
                loading: false,

            })
        } else {
            this.setState({
                loading: true,
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.data !== this.props.data) {

            this.getValue();
        }
    }

    componentDidMount(): void {

    }

    render(): React.ReactNode {
        return (
            <>
                {
                    this.state.loading !== true && <div className="box-keterangan-polagram mt-4">
                        <div className="box-keterangan-polagram-content">
                            <h6 className="text-blacks text-center">Polagram (Pola Pikiran Perasaan Perilaku)</h6>


                            <div className="keterangan-polagram-body">
                                {
                                    this.state.loading === true ? <>
                                        <div className="text-center mt-5">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </> : <></>
                                }
                                <div className="row">
                                    <div className="col col-sm">
                                        <ul>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_1[0].warna}`}></div>
                                                    <span>Futuristic</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_1[1].warna}`}></div>
                                                    <span>Strategic</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_1[2].warna}`}></div>
                                                    <span>Ideation</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_1[3].warna}`}></div>
                                                    <span>Analytical</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_1[4].warna}`}></div>
                                                    <span>Learner</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_1[5].warna}`}></div>
                                                    <span>Intellection</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_1[6].warna}`}></div>
                                                    <span>Input</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_1[7].warna}`}></div>
                                                    <span>Context</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col col-sm">
                                        <ul>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Command</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_2[0].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Activator</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_2[1].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Competition</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_2[2].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Maximizer</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_2[3].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Significance</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_2[4].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Self Assurance</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_2[5].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Communication</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_2[6].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>WOO</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_2[7].warna}`}></div>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="text-center mt-3 mb-5">
                                    <img src="/images/keterangan_polagram.png" width={60} alt="" />
                                </div>

                                <div className="row">
                                    <div className="col col-sm">
                                        <ul>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[0].warna}`}></div>
                                                    <span>Restorative</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[1].warna}`}></div>
                                                    <span>Deliberative</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[2].warna}`}></div>
                                                    <span>Arranger</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[3].warna}`}></div>
                                                    <span>Discipline</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[4].warna}`}></div>
                                                    <span>Consistency</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[5].warna}`}></div>
                                                    <span>Focus</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[6].warna}`}></div>
                                                    <span>Achiever</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[7].warna}`}></div>
                                                    <span>Responsibility</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item">
                                                    <div className={`keterangan-polagram-bg bg-${this.state.line_3[8].warna}`}></div>
                                                    <span>Belief</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col col-sm">
                                        <ul>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Positivity</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[0].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Developer</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[1].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Relator</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[2].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Includer</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[3].warna}`}></div>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Harmony</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[4].warna}`}></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Adaptability</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[5].warna}`}></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Individualization</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[6].warna}`}></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Connectedness</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[7].warna}`}></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="keterangan-polagram-item text-end">
                                                    <span>Empathy</span>
                                                    <div className={`keterangan-polagram-bg-right bg-${this.state.line_4[8].warna}`}></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </>
        )
    }
}

export default KeteranganPolagram;