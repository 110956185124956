import React from "react";
import NavbarIsi from "./NavbarIsi";
import FooterButton from "./FooterButton";
import HrCustom from "../etc/HrCustom";
import ProfileModule from "../module/Profile";
import { Link, Navigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Loading from "../etc/Loading";

class Step1 extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            img_choose: "/images/pp_1.png",
            comp_img: "",
            file_upload: "",
            auth: localStorage.getItem("user-rapidtest"),
            loading: false,
            disabled: false,
            navigation: false
        }

        this.getImg = this.getImg.bind(this);
        this.getImgApi = this.getImgApi.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleUploadInput = this.handleUploadInput.bind(this);
    }

    handleUpload() {
        this.setState({
            loading: true,
            disabled: true,
        })
        if (this.state.file_upload.length === 0) {
            const data = {
                image: this.state.img_choose,
            }
            ProfileModule.changeDefaultProfile(this.state.auth, data).then((result: any) => {

                if (result.response === true) {
                    this.setState({
                        loading: false,
                        disabled: false,
                        navigation: <Navigate to={"/step2"} />
                    })
                }
            })
        } else {
            const data = {
                avatar: this.state.file_upload,
            }

            ProfileModule.uploadImage(this.state.auth, data).then((result: any) => {

                if (result.response === true) {
                    this.setState({
                        loading: false,
                        disabled: false,
                        navigation: <Navigate to={"/step2"} />
                    })
                }
            })
        }
    }

    getImg(e) {
        this.setState({
            img_choose: e.target.getAttribute('src'),
        });
    }

    getImgApi() {
        const data_auth = localStorage.getItem("user-rapidtest");

        ProfileModule.getDefaultProfile(data_auth).then((result: any) => {

            if (result.response === true) {
                const comp = result.data.result.map(el => {
                    return (
                        <>
                            <div className="isi-pp-item">
                                <img src={`${el.url}`} className={this.state.img_choose === `${el.url}` ? "active" : ""} onClick={this.getImg} alt="" />
                            </div>
                        </>
                    )
                });

                this.setState({
                    comp_img: comp,
                })
            }
        })
    }

    handleUploadInput(e) {
        this.setState({
            file_upload: e.target.files[0],
        })
    }

    componentDidMount(): void {
        this.getImgApi();


        const get_local = JSON.parse(localStorage.getItem("step_profile"));

        if (get_local === null) {
            localStorage.setItem("step_profile", JSON.stringify({
                name: "",
                address: "",
                phone: "",
                date: "",
                jenkel: "",
                cita_cita: [],
                kesukaan: [],
                school: "",
                kelas: "",
                jurusan: "",
            }));
        } else {
            setTimeout(() => {
                if (get_local.length === 0) {
                    localStorage.setItem("step_profile", JSON.stringify({
                        name: "",
                        address: "",
                        phone: "",
                        date: "",
                        jenkel: "",
                        cita_cita: [],
                        kesukaan: [],
                        school: "",
                        kelas: "",
                        jurusan: "",
                    }));
                }
            }, 500)
        }

    }

    render(): React.ReactNode {
        return (
            <>
                {
                    this.state.navigation
                }
                <div className="container">
                    <NavbarIsi step={1} />

                    <div className="box-profile">
                        <h5>Pilih Foto Profile Kamu!</h5>

                        <HrCustom icon="face_man" />

                        <div className="isi-pp mt-4">
                            <div className="substract-1">
                                <img src="./images/circle_profile.png" alt="" />
                            </div>
                            <div className="substract-2">
                                <img src="./images/circle_profile.png" alt="" />
                            </div>

                            <div className="text-center img-isi-pp">
                                <img src={this.state.img_choose} alt="" />
                            </div>
                        </div>

                        <div className="isi-pp-catalogue">
                            <p>Silahkan pilih avatar berikut ini!</p>
                            <div className="isi-pp-content">
                                {
                                    this.state.comp_img
                                }
                            </div>
                        </div>

                        <div className="isi-profile-custom mt-3">
                            <p>Kamu juga bisa upload foto profilmu sendiri.</p>
                            <input type="file" name="file-input" className="file-input" onChange={this.handleUploadInput} ></input>
                        </div>
                    </div>
                </div>

                <div className="footer-button">
                    <div className="footer-button-content">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <Link to={"/step2"} className="btn-no-border btn-primary text-second btn no-underline pe-4 ps-4 pt-2 pb-2">Lewati</Link>
                            </div>
                            <div className="">
                                <Button className="btn btn-primary pe-4 ps-4 pt-2 pb-2" disabled={this.state.disabled} onClick={this.handleUpload}><Loading show={this.state.loading} />Lanjut</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Step1;