import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

class ModalLogout extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.props.isOpen} onHide={this.props.closeModal}>
                    <Modal.Body>
                        <div className="header-modal">
                            <img src={"/images/modal_danger.png"} alt="" />
                        </div>
                        <div className="modal-title">
                            <p>Logout</p>
                        </div>
                        <div className="modal-message">
                            <p>Apakah kamu yakin untuk keluar dari aplikasi Ambisius Meter?</p>
                        </div>

                        <div className="d-flex">
                            <div className="p-2 flex-grow-1">
                                <Button className="btn-no-border" onClick={this.props.closeModal}>CANCEL</Button>
                            </div>
                            <div className="p-2">
                                <Link className="btn btn-danger ps-4 pe-4" to={"/logout"}>LOGOUT</Link>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ModalLogout;