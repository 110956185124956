import React from "react";

class Time extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="box-time">
                    <div className="d-flex">
                        <div className="me-1">
                            <span>Waktu: </span>
                        </div>
                        <div className="box-time-item me-1">
                            <p>{this.props.timer[0]}</p>
                        </div>
                        <div className="box-time-item me-1">
                            <p>{this.props.timer[1]}</p>
                        </div>
                        <div className="box-time-item-titik me-1">
                            <p>:</p>
                        </div>
                        <div className="box-time-item me-1">
                            <p>{this.props.timer[2]}</p>
                        </div>
                        <div className="box-time-item me-1">
                            <p>{this.props.timer[3]}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Time;