import React from "react";
import Auth from "./module/Auth";
import { Navigate, Outlet } from "react-router-dom";
import LoadingFull from "./etc/LoadingFull";


class AuthCheck extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        const data_auth = localStorage.getItem("user-rapidtest");

        if (data_auth) {
            Auth.checkAuth(data_auth).then((result: any) => {
                if (result.response === true) {
                    const auth = JSON.parse(data_auth);
    
                    if (auth.kelengkapan_profile === false) {
                        // Redirect to the first step of profile completion
                        this.setState({
                            isLogged: <Navigate to="/step1" replace={true} />,
                            loading: false,
                        });
                    } else {
                        // User is authenticated and profile is complete
                        this.setState({
                            isLogged: <Outlet />,
                            loading: false,
                        });
                    }
                } else {
                    // User is not authenticated
                    this.setState({
                        isLogged: <Navigate to="/" replace={true} />,
                        loading: false,
                    });
                }
            });
        } else {
            // No authentication data found
            this.setState({
                isLogged: <Navigate to="/" replace={true} />,
                loading: false,
            });
        }
    }

    render() {
        return (
            <>
                {this.state.isLogged}
                <LoadingFull display={this.state.loading} />
            </>
        );
    }
}


export default AuthCheck;