import React from "react";

class ResultValueRiasec extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            line_1: "",
        }
    }

    getHasil() {
        if (this.props.result.length !== 0) {

            const line_1 = this.props.result.data.slice(0, 3)
            const line_2 = this.props.result.data.slice(3, 6)


            const hasil_1 = line_1.map((el) => {
                return (
                    <>
                        <div className={`value-kepribadian-item width-88 bg-${el.warna} me-2`}>
                            <h5>{el.score}</h5>
                            <p>{el.nama}</p>
                        </div>
                    </>
                )
            });

            const hasil_2 = line_2.map((el) => {
                return (
                    <>
                        <div className={`value-kepribadian-item width-88 bg-${el.warna} me-2`}>
                            <h5>{el.score}</h5>
                            <p>{el.nama}</p>
                        </div>
                    </>
                )
            });
            this.setState({
                line_1: hasil_1,
                line_2: hasil_2
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.result !== this.props.result) {
            this.getHasil();
        }
    }

    componentDidMount(): void {
        this.getHasil();
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="value-kepribadian mt-4">
                    <div className="value-kepribadian-content">
                        <div className="d-flex justify-content-center">
                            {
                                this.state.line_1
                            }

                        </div>

                        <div className="d-flex justify-content-center">
                            {
                                this.state.line_2
                            }
                        </div>



                    </div>
                </div>
            </>
        )
    }
}

export default ResultValueRiasec;