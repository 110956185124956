import React from "react";
import Helmet from 'react-helmet';
import Copyright from "./etc/Copyright";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Etc from "./module/Etc";
import AlertBottom from "./etc/AlertBottom";
import Auth from "./module/Auth";
import { Navigate } from "react-router-dom";

class LupaPassword extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            disabled: true,
            msg_email: "",
            data_auth: localStorage.getItem("user-rapidtest"),
            navigation: false,
        }

        this.validated = this.validated.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSend = this.handleSend.bind(this);
    }

    handleSend() {
        Auth.sendOtp({ email: this.state.email }).then((result: any) => {
            console.log(result);
            if (result.response === true) {
                if (result.data !== undefined) {
                    this.setState({
                        email: "",
                        msg_email: <AlertBottom show={true} msg="Email tidak ditemukan." />,
                        disabled: true,
                    })
                } else {
                    this.setState({
                        navigation: <Navigate to="/verifikasi" state={{ email: this.state.email }} />
                    });
                }

            } else {
                this.setState({
                    email: "",
                    msg_email: <AlertBottom show={true} msg="Terjadi kesalahan." />,
                    disabled: true,
                })
            }
        })
    }

    validated() {
        if (Etc.isObjectEmpty({ email: this.state.email })) {
            this.setState({ disabled: false });
        } else {
            this.setState({ disabled: true });
        }
    }

    handleEmail(e) {
        if (e.target.value.length === 0) {
            this.setState({
                msg_email: <AlertBottom show={true} msg="Email tidak boleh kosong." />,
                email: e.target.value,
            }, () => {
                this.validated();
            });
        } else if (!Etc.checkEmail(e.target.value)) {
            this.setState({
                msg_email: <AlertBottom show={true} msg="Email tidak valid." />,
                email: e.target.value,
                disabled: true,
            });
        } else {
            this.setState({
                msg_email: "",
                email: e.target.value,
            }, () => {
                this.validated();
            })
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <Helmet>
                    <title>Lupa Password | Ambisius Meter</title>
                </Helmet>
                {this.state.navigation}
                <div className='container'>
                    <div className='me-3 ms-3'>
                        <div className='logo-img'>
                            <img style={{ width: "256px" }}  src="./images/logo.png" alt="" />
                        </div>

                        <div className='mt-5'>
                            <h1 className='text-uppercase text-blacks text-blue'>LUPA PASSWORD</h1>
                            <p>Silahkan masukan email kamu untuk melanjutkan ke tahap berikutnya!</p>
                        </div>

                        <div className='hr-blue mb-3 w-25'></div>
                    </div>

                    <div className='box-blue mt-5'>
                        <div className='box-blue-content'>
                            <div className='form-group'>
                                <label htmlFor="">Email</label>
                                <input type="email" onChange={this.handleEmail} value={this.state.email} className='form-control' />
                                {this.state.msg_email}
                            </div>


                            <div className='form-group' style={{ marginTop: 40 }}>
                                <div className='d-grid'>
                                    <Button className='btn btn-primary text-uppercase' onClick={this.handleSend} disabled={this.state.disabled}>selanjutnya</Button>
                                </div>
                            </div>

                            <div className='form-group text-center mt-4'>
                                <span className="text-muted"><Icon icon={"ph:arrow-u-up-left-bold"} /><a href="/" className="text-muted text-extrabold"> Kembali Ke Halaman Login</a></span>
                            </div>
                        </div>
                    </div>
                    <Copyright />

                </div>
            </>
        )
    }
}

export default LupaPassword;