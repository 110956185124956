import React from 'react';

class OtpInput extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            otp: new Array(6).fill(""),
            active: false,
        }
    }

    isObjectEmpty(value: any) {
        return Object.values(value).every(x => x !== '');
    }

    validate() {
        setTimeout(() => {
            if (this.isObjectEmpty(this.state.otp)) {
                this.props.setDisabled(false);
            } else {
                this.props.setDisabled(true);
            }
        }, 1000)
    }

    handleOtp(e, index) {
        if (isNaN(e.target.value)) return false;

        this.setState({
            otp: [...this.state.otp.map((d, idx) => (idx === index ? e.target.value : d))]
        });

        if (e.target.nextSibling) {
            e.target.nextSibling.focus();
        }

        setTimeout(() => {
            this.props.setOtp(this.state.otp);
        }, 500)
        this.validate()

    }

    handleDel(e) {
        if (e.key === "Delete" || e.key === "Backspace") {
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
            this.validate()

        }

    }

    render(): React.ReactNode {
        return (
            <>
                {
                    this.state.otp.map((data, index) => {
                        return (
                            <input
                                id='otp'
                                type='text'
                                name='otp'
                                maxLength={1}
                                className={`border-0 bg me-2 rounded-0 fb text-center form-control otp-${index}`}
                                style={{ borderBottom: this.state.active ? "#61A3FB" : "" }}
                                key={index}
                                value={data}
                                onChange={(e) => this.handleOtp(e, index)}
                                onFocus={(e) => e.target.select}
                                autoFocus={index === 0}
                                onKeyUp={(e) => this.handleDel(e)}
                            />
                        )
                    })
                }
            </>
        )
    }
}

export default OtpInput;