import axios from "./axios";

class ProfileModule {
    static async getKelas(data_auth) {
        const auth:any = JSON.parse(data_auth);
        return new Promise((res, rej) => {
            axios({
                method: "GET",
                url: "/api/siswa/profile/kelas",
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,                
                }
            }).then((result) => {
                
                if (result.data.data.status.code === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async getJurusan(data_auth) {
        const auth:any = JSON.parse(data_auth);
        return new Promise((res, rej) => {
            axios({
                method: "GET",
                url: "/api/siswa/profile/jurusan",
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,                
                }
            }).then((result) => {
                
                if (result.data.data.status.code === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async getCita(data_auth) {
        const auth:any = JSON.parse(data_auth);
        return new Promise((res, rej) => {
            axios({
                method: "GET",
                url: "/api/siswa/profile/get-cita",
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,                
                }
            }).then((result) => {
                
                if (result.status === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async getKesukaan(data_auth) {
        const auth:any = JSON.parse(data_auth);
        return new Promise((res, rej) => {
            axios({
                method: "GET",
                url: "/api/siswa/profile/get-kesukaan",
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,                
                }
            }).then((result) => {
                
                if (result.status === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async getDefaultProfile(data_auth) {
        const auth:any = JSON.parse(data_auth);
        return new Promise((res, rej) => {
            axios({
                method: "GET",
                url: "/api/siswa/profile/list_gambar_default",
                headers: {
                    Accept: "application/json",
                    "Authorization": "Bearer " + auth.token,                
                }
            }).then((result) => {
                
                if (result.data.data.status.code === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async sendChange(data_auth, data) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            axios({
                method: "POST",
                url: "/api/siswa/profile/send-change-profile",
                headers: {
                    Accept: "application/json",
                    'Authorization': 'Bearer ' + auth.token,
                    "Access-Control-Allow-Origin": "*"
                },
                data: data
            }).then((result) => {
                
                if (result.status === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async changeDefaultProfile(data_auth, data) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            axios({
                method: "POST",
                url: "/api/siswa/profile/send_image_url",
                headers: {
                    Accept: "application/json",
                    'Authorization': 'Bearer ' + auth.token,
                    "Access-Control-Allow-Origin": "*"
                },
                data: data
            }).then((result) => {
                
                if (result.data.result.code === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async uploadImage(data_auth, data) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            const formData = new FormData();
            formData.append('avatar', data.avatar);
            axios({
                method: "POST",
                url: "/api/siswa/profile/send-edit-image",
                headers: {
                    Accept: "application/json",
                    'Authorization': 'Bearer ' + auth.token,
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': "multipart/form-data",
                },
                data: formData
            }).then((result) => {
                
                if (result.status === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }
}

export default ProfileModule;