import { Icon } from "@iconify/react";
import React from "react";
import { NavLink } from "react-router-dom";


class NavigationBar extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="navbar-bottom">
                    <div className="navbar-bottom-content">
                        <div className="d-flex">
                            <div className="text-center w-100">
                                <NavLink to={"/dashboard"} className={({ isActive }) => isActive ? "navbar-bottom-icon active" : "navbar-bottom-icon"}>
                                    <Icon icon={"ant-design:home-filled"} />
                                    <p>DASHBOARD</p>
                                </NavLink>
                            </div>
                            <div className="text-center w-100">
                                <NavLink to={"/asesmen"} className={({ isActive }) => isActive ? "navbar-bottom-icon active" : "navbar-bottom-icon"} >
                                    <Icon icon={"healthicons:i-exam-multiple-choice"} />
                                    <p>ASESMEN</p>
                                </NavLink>
                            </div>
                            <div className="text-center w-100">
                                <NavLink to={"/profile"} className={({ isActive }) => isActive ? "navbar-bottom-icon active" : "navbar-bottom-icon"}>
                                    <Icon icon={"mdi:face-man-profile"} />
                                    <p>PROFILE</p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NavigationBar;