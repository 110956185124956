import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import Assesment from "../module/Assesment";

class ModalResult extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            img: "",
            soal: "102",
            navigation: false,
            loading: this.props.isLoading
        }

        this.getSoal = this.getSoal.bind(this);
        this.handleHasil = this.handleHasil.bind(this);
    }

    getSoal() {
        if (this.props.title === "Polagram") {
            this.setState({
                img: "polagram_card",
            });
        } else if (this.props.title === "Tes Gaya Belajar") {
            this.setState({
                img: "gaya_card",
            });
        } else if (this.props.title === "RIASEC") {
            this.setState({
                img: "riasec_card",
            });
        } else if (this.props.title === "Kepribadian") {
            this.setState({
                img: "kepribadian_card",
            });
        } else if (this.props.title === "IQ") {
            this.setState({
                img: "iq_card",
            })
        }
    }

    handleHasil() {
        if (this.state.loading) {
            return;
        }
    
        // Tetapkan loading menjadi true untuk menghindari pengiriman ganda
        this.setState({ loading: true });
        this.props.onLoadingStart();

        const get_answer = JSON.parse(localStorage.getItem("answer"));
        const get_pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));
        const get_curr = JSON.parse(localStorage.getItem("current_soal"));
        const data_auth = localStorage.getItem("user-rapidtest");

        // convert into string
        const arr_answer = get_answer.map(el => {

            return Array.from(el, x => `${x}`);
        });

        const data = {
            id_pengiriman: get_pagination.pengiriman.toString(),
            jawaban: arr_answer,
        }



        Assesment.sendAnswer(data_auth, data).then((result: any) => {

            this.setState({
                navigation: <Navigate to={this.props.to} state={{ data: { id: get_curr[0], name: this.props.title } }} replace={true} />
            });
        });
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.props.title !== prevProps.title) {
            this.getSoal();
        }
        if (this.props.isLoading !== prevProps.isLoading) {
            this.setState({ loading: this.props.isLoading });
        }
    }

    componentDidMount(): void {
        this.getSoal();
    }

    render(): React.ReactNode {
        return (
            <>
                {
                    this.state.navigation
                }
                <Modal show={this.props.isOpen} backdrop="static" onHide={this.props.closeModal}>
                    <Modal.Body>
                        <div className="header-modal">
                            <img src={"/images/modal_success.png"} alt="" />
                        </div>
                        <div className="modal-title">
                            <p>Kamu Sudah Menyelesaikan Asesmen {this.props.title}</p>
                        </div>
                        <div className="modal-message">
                            <div className="image-modal mt-4 mb-4">
                                <img src={`/images/${this.state.img}.png`} width={80} alt="" />
                            </div>
                            Silahkan klik tombol “HASIL” untuk melihat penilaian secara psikologis yang akan kamu dapatkan dari Asesmen {this.props.title}.
                        </div>

                        <div className="d-flex mt-3">
                            <div className="p-2 flex-grow-1">
                                {/* <Button className="btn-no-border" onClick={this.props.closeModal}>CANCEL</Button> */}
                            </div>
                            <div className="p-2">
                                <Button className="btn btn-primary ps-4 pe-4" onClick={this.handleHasil}>HASIL</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ModalResult;