import React from "react";
import { Navigate } from "react-router-dom";
import Auth from "./module/Auth";
import LoadingFull from "./etc/LoadingFull";

interface RedirectIfAuthenticatedProps {
    children: React.ReactNode;
}


interface RedirectIfAuthenticatedState {
    isAuthenticated: boolean | null;
    loading: boolean;
}


class RedirectIfAuthenticated extends React.Component<RedirectIfAuthenticatedProps, RedirectIfAuthenticatedState> {
    constructor(props: RedirectIfAuthenticatedProps) {
        super(props);
        this.state = {
            isAuthenticated: null,
            loading: true, 
        };
    }

    componentDidMount() {
        const token = localStorage.getItem('user-rapidtest');
        if (token) {
            Auth.checkAuth(token).then((result: any) => {
                this.setState({ 
                    isAuthenticated: result.response,
                    loading: false, // Update loading state here
                });
                if (!result.response) {
                    localStorage.clear();
                }
            });
        } else {
            this.setState({ 
                isAuthenticated: false,
                loading: false, // Update loading state here
            });
        }
    }
    

    render() {
        const { isAuthenticated } = this.state;
        const { children } = this.props;

        if (isAuthenticated === null) {
            <LoadingFull display={this.state.loading} />
        }

        return isAuthenticated ? <Navigate to="/dashboard" /> : children;
    }
}

export default RedirectIfAuthenticated;
