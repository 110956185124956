import axios from "./axios";

class Auth {
    static async sendLogin(data) {
        return new Promise((res, rej) => {
            axios({
                method: 'POST',
                url: "/api/siswa/send-login",
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Accept: "application/json",
                    'Content-Type': "multipart/form-data",
                },
                data: data
            }).then((result) => {
                
                if (result.data.data.status.code !== 202) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            }).catch((err) => {
                
            })
        })
    }

    static async checkAuth(data_auth) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            
            axios({
                method: "GET",
                url: "/api/siswa/profile/get-profile",
                headers: {
                    Accept: "application/json",
                    'Authorization': 'Bearer ' + auth.token,
                    "Access-Control-Allow-Origin": "*"
                },
            }).then((result) => {
                
                if (result.data.data.status.code !== 202) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            }).catch((rejects) => {
                res({response: false});
            })
        })
    }

    static async sendLogout(data_auth) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            
            axios({
                method: "POST",
                url: "/api/siswa/send-logout",
                headers: {
                    Accept: "application/json",
                    'Authorization': 'Bearer ' + auth.token,
                    "Access-Control-Allow-Origin": "*"
                },
            }).then((result) => {
                
                res({response: true, data: result.data.data});
            }).catch((rejects) => {
                res({response: false});
            })
        })
    }

    static async sendChangePassword(data_auth, data) {
        return new Promise((res, rej) => {
            const auth = JSON.parse(data_auth);
            axios({
                method: "POST",
                url: "/api/siswa/profile/send-change-password",
                headers: {
                    Accept: "application/json",
                    'Authorization': 'Bearer ' + auth.token,
                    "Access-Control-Allow-Origin": "*"
                },
                data: data
            }).then((result) => {
                
                if (result.status === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data.data});
                }
            })
        })
    }

    static async sendOtp(data) {
        return new Promise((res, rej) => {
            axios({
                method: "POST",
                url: "/api/siswa/send-otp",
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                data: data
            }).then((result) => {
                console.log(result);
                if (result.status === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data});
                }
            })
        })
    }

    static async sendForget(data) {
        return new Promise((res, rej) => {
            axios({
                method: "POST",
                url: "/api/siswa/login_forget",
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                data: data
            }).then((result) => {
                console.log(result);
                if (result.status === 200) {
                    res({response: true, data: result.data.data});
                } else {
                    res({response: false, data: result.data});
                }
            })
        })
    }
}

export default Auth;