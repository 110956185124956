import React from "react";
import Helmet from 'react-helmet';
import Copyright from "./etc/Copyright";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import ModalButton from "./modal/ModalButton";
import AlertBottom from "./etc/AlertBottom";
import Auth from "./module/Auth";
import Loading from "./etc/Loading";
import Etc from "./module/Etc";

class GantiPassword extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            data: {
                password_old: "",
                password_new: "",
                password_confirm: "",
            },
            msg_passwordnew: "",
            msg_passwordold: "",
            msg_passwordconfirm: "",
            disabled: true,
            loading: false,
        }

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handlePasswordNew = this.handlePasswordNew.bind(this);
        this.handlePasswordOld = this.handlePasswordOld.bind(this);
        this.handlePasswordConfirm = this.handlePasswordConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validated = this.validated.bind(this);
    }

    validated() {
        setTimeout(() => {
            if (Etc.isObjectEmpty(this.state.data)) {
                this.setState({ disabled: false });
                if (this.state.data.password_new === this.state.data.password_confirm) {
                    this.setState({ disabled: false });
                } else {
                    this.setState({ disabled: true });
                }
            } else {
                this.setState({ disabled: true });
            }



        }, 1000)
    }

    openModal() {
        this.setState({
            isOpen: true,
        });
    }

    closeModal() {
        this.setState({
            isOpen: false,
        })
    }

    handlePasswordNew(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                password_new: e.target.value,
            }
        }));
        this.validated();
        if (e.target.value.length === 0) {
            this.setState({
                msg_passwordnew: <AlertBottom show={true} msg="Password tidak boleh kosong." />
            });
        } else {
            this.setState({
                msg_passwordnew: "",
            });
        }
    }

    handlePasswordOld(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                password_old: e.target.value,
            }
        }));
        this.validated();
        if (e.target.value.length === 0) {
            this.setState({
                msg_passwordold: <AlertBottom show={true} msg="Password Lama tidak boleh kosong." />
            });
        } else {
            this.setState({
                msg_passwordold: "",
            });
        }
    }

    handlePasswordConfirm(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                password_confirm: e.target.value,
            }
        }));
        this.validated();

        if (e.target.value.length === 0) {
            this.setState({
                msg_passwordconfirm: <AlertBottom show={true} msg="Konfirmasi Password tidak boleh kosong." />
            });
        } else {

            if (e.target.value === this.state.data.password_new) {
                this.setState({
                    msg_passwordconfirm: "",
                    disabled: false,
                });
            } else {
                this.setState({
                    msg_passwordconfirm: <AlertBottom show={true} msg="Password tidak sama." />,
                    disabled: true,
                });
            }

        }
    }

    handleChange() {
        this.setState({
            loading: true,
            disabled: true,
        })
        const data_auth = localStorage.getItem("user-rapidtest");
        const data = {
            old_password: this.state.data.password_old,
            new_password: this.state.data.password_new,
            confirm_password: this.state.data.password_confirm,
        }

        Auth.sendChangePassword(data_auth, data).then((result: any) => {

            if (result.response === true) {
                this.setState({
                    loading: false,
                    disabled: false,
                });

                this.openModal();
            } else {
                alert("Terjadi kesalahan.");
            }
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Helmet>
                    <title>Ganti Password | Ambisius Meter</title>
                </Helmet>

                <div className='container'>
                    <div className='me-3 ms-3'>
                        <div className='logo-img'>
                            <img style={{ width: "256px" }} src="/images/logo.png" alt="" />
                        </div>

                        <div className='mt-5'>
                            <h1 className='text-uppercase text-blacks text-blue'>GANTI PASSWORD</h1>
                            <p>Silahkan isi kolom dibawah ini untuk mengganti password kamu!</p>
                        </div>

                        <div className='hr-blue mb-3 w-25'></div>
                    </div>

                    <div className='box-blue mt-5'>
                        <div className='box-blue-content'>
                            <div className='form-group'>
                                <label htmlFor="">Password Lama</label>
                                <input type="password" onChange={this.handlePasswordOld} className='form-control' />
                                {this.state.msg_passwordold}
                            </div>
                            <div className='form-group mt-2'>
                                <label htmlFor="">Password Baru</label>
                                <input type="password" onChange={this.handlePasswordNew} className='form-control' />
                                {this.state.msg_passwordnew}
                            </div>

                            <div className='form-group mt-2'>
                                <label htmlFor="">Konfirmasi Password</label>
                                <input type="password" onChange={this.handlePasswordConfirm} className='form-control' />
                                {this.state.msg_passwordconfirm}
                            </div>


                            <div className='form-group' style={{ marginTop: 40 }}>
                                <div className='d-grid'>
                                    <Button className='btn btn-primary text-uppercase' disabled={this.state.disabled} onClick={this.handleChange}>Ganti password <Loading show={this.state.loading} /></Button>
                                </div>
                            </div>

                            <div className='form-group text-center mt-4'>
                                <span className="text-muted"><Icon icon={"ph:arrow-u-up-left-bold"} /><a href="/" className="text-muted text-extrabold"> Kembali Ke Halaman Login</a></span>
                            </div>
                        </div>
                    </div>
                    <Copyright />
                </div >

                <ModalButton isOpen={this.state.isOpen} closeModal={this.closeModal} status={"success"} title={"Password Berhasil Diganti"} message={<><p>Password kamu telah berhasil diganti. Silahkan klik tombol <i>“Lanjut”</i> untuk kembali ke halaman login.</p></>} to={"/logout"} />
            </>
        )
    }
}

export default GantiPassword;