import React from "react";

class StatusCheck extends React.Component<any, any> {
    constructor(props) {
        super(props);

    }

    render(): React.ReactNode {
        return (
            <>
                <div>
                    {this.props.status === true ? <>
                        <div className="d-flex">
                            <div className="pe-2 align-self-center">
                                <p className="text-green-bold">Sudah Dikerjakan</p>
                            </div>
                            <div className="align-self-center">
                                <img src="./images/checklist_green.png" alt="" />
                            </div>
                        </div>
                    </> : <>
                        <div className="d-flex">
                            <div className="pe-2 align-self-center">
                                <p className="text-red-bold">Belum Dikerjakan</p>
                            </div>
                            <div className="align-self-center">
                                <img src="./images/cross_red.png" alt="" />
                            </div>
                        </div>
                    </>}
                </div>
            </>
        )
    }
}

export default StatusCheck;