import React from "react";

class Progress extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            max: 0,
            list_total: 0,
            width: 0
        }

        this.getResult = this.getResult.bind(this);
    }

    getResult() {

        var no = 1;
        const pengerjaan = this.props.result.filter(x => x.pengerjaan === true);
        const result = (pengerjaan.length + '0').slice(-2);
        var width = "";
        if (result === '10') {
            width = '20';
        } else if (result === '20') {
            width = '40';
        } else if (result === '30') {
            width = '60';
        } else if (result === '40') {
            width = '80';
        } else if (result === '50') {
            width = '100';
        }
        this.setState({
            max: pengerjaan.length,
            list_total: this.props.result.length,
            width: width
        })
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.result !== this.props.result) {
            this.getResult();

        }
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="progress mt-4" role="progressbar" aria-valuenow={this.state.width} aria-valuemin={0} aria-valuemax={this.state.list_total * 20}>
                    <div className="progress-bar" style={{ width: `${this.state.width}%` }}>{this.state.width}%</div>
                </div>
                <p className="text-extrabold mt-2 text-end" style={{ fontSize: 12 }}>{this.state.max}/{this.state.list_total} Tes sudah dikerjakan</p>

            </>
        )
    }
}

export default Progress;