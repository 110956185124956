import React from "react";
import Helmet from 'react-helmet';
import NavbarHome from "../etc/NavbarHome";
import StatusCheck from "../etc/StatusCheck";
import Copyright from "../etc/Copyright";
import { Button } from "react-bootstrap";
import NavigationBar from "../etc/NavigationBar";
import Sidebar from "../etc/Sidebar";
import Progress from "../etc/Progress";
import InfoSekolah from "../etc/InfoSekolah";
import { Link } from "react-router-dom";
import { withRouter } from "../etc/withRouter";
import Auth from "../module/Auth";
import LoadingFull from "../etc/LoadingFull";
import Assesment from "../module/Assesment";

class Dashboard extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            isSidebar: false,
            nama: "",
            data_user: {
                nama: "",
                kelas: "",
                jurusan: "",
                sekolah: "",
                nis: "",
                img: ""
            },
            loading: true,
            list_assesment: [],
            asses: "",
        }

        this.openSidebar = this.openSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAssesment = this.getAssesment.bind(this);

    }

    getProfile() {
        const data_auth = localStorage.getItem("user-rapidtest");
        Auth.checkAuth(data_auth).then((result: any) => {

            if (result.response === true) {
                this.setState(prevState => ({
                    data_user: {
                        ...prevState.data_user,
                        nama: result.data.result.info_pribadi.nama,
                        kelas: result.data.result.sekolah.Kelas.nama_kelas,
                        jurusan: result.data.result.sekolah.Jurusan.nama_jurusan,
                        sekolah: result.data.result.sekolah.sekolah,
                        nis: result.data.result.sekolah.nis,
                        img: result.data.result.info_pribadi.foto,
                    },
                    loading: false,
                }));
            }
        })
    }

    getAssesment() {
        const data_auth = localStorage.getItem("user-rapidtest");

        Assesment.getAssesment(data_auth).then((result: any) => {

            if (result.response === true) {
                const component = result.data.result.map(el => {
                    return (
                        <li className={`list-group-item mb-3 pe-3 ps-3 pt-2 pb-2 ${el.name.toLowerCase().split(' ').join('_')}_card`}>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <div className="progress-item">
                                        <p>{el.name}</p>
                                    </div>
                                </div>
                                <div>
                                    <StatusCheck status={el.pengerjaan} />
                                </div>
                            </div>
                        </li>
                    )
                });

                this.setState({
                    list_assesment: component,
                    loading: false,
                    asses: result.data.result,
                })


            }
        })
    }


    openSidebar() {
        this.setState({
            isSidebar: true,
        })
    }

    closeSidebar() {
        this.setState({
            isSidebar: false
        })
    }



    componentDidMount(): void {
        this.getProfile();
        this.getAssesment();
        const name = JSON.parse(localStorage.getItem("user-rapidtest"));
        this.setState({
            nama: name.nama_lengkap
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Helmet>
                    <title>Dashboard | Ambisius Meter</title>
                </Helmet>
                <LoadingFull display={this.state.loading} />
                <div className="container">
                    <NavbarHome img={this.state.data_user.img} openSidebar={this.openSidebar} nama={this.state.nama} />
                    <div className="banner-item mt-2">
                        <img src="./images/banner/banner_1.png" className="img-fluid" alt="" />
                    </div>
                </div>

                <div className="box-purple">
                    <div className="box-purple-content">
                        <h5 className="text-blacks mb-3">Profile Kamu</h5>
                        <InfoSekolah data_user={this.state.data_user} />
                    </div>
                </div>


                <div className="box-progress">
                    <div className="box-progress-content">
                        <div className="container">
                            <h5 className="text-blacks">Progress Asesmen Kamu</h5>

                            <Progress result={this.state.asses} />


                            <ul className="list-group mt-4">
                                {this.state.list_assesment}
                            </ul>

                            <div className="d-grid mt-5">
                                <Link to={"/asesmen"} className="btn btn-primary">KERJAKAN ASESMEN</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <Copyright style={{ marginBottom: 100 }} />
                <NavigationBar />
                <Sidebar isSidebar={this.state.isSidebar} img={this.state.data_user.img} nama={this.state.nama} closeSidebar={this.closeSidebar} nis={this.state.data_user.nis} />
            </>
        )
    }
}

export default withRouter(Dashboard);