import React from "react";
import NavbarResult from "../NavbarResult";
import { Icon } from "@iconify/react";
import ResultValueRiasec from "./ResultValueRiasec";
import ImageResultRiasec from "./ImageResultRiasec";
import AccordionRiasec from "./AccordionRiasec";
import Copyright from "../../etc/Copyright";
import Assesment from "../../module/Assesment";
import Etc from "../../module/Etc";
import { withRouter } from "../../etc/withRouter";
import LoadingFull from "../../etc/LoadingFull";

class ResultRiasec extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            nama: "",
            get_auth: localStorage.getItem("user-rapidtest"),
            result_hasil: [],
            loading: true,
            max_value: [],
            tgl: "",
        }

        this.getHasil = this.getHasil.bind(this);

    }

    getHasil(get_auth) {

        const data = {
            id: this.props.location.state.data.id.toString(),
            name: this.props.location.state.data.name,
        }

        Assesment.getHasil(get_auth, data).then((result: any) => {

            if (result.response === true) {
                const max = Etc.getDesc(result.data.result.data)
                this.setState({
                    result_hasil: result.data.result,
                    loading: false,
                    max_value: max.slice(0, 2),
                    tgl: result.data.result.tanggal_pengerjaan
                });
            }
        })
    }

    componentDidMount(): void {
        const get_auth = JSON.parse(this.state.get_auth);
        this.getHasil(this.state.get_auth);

        this.setState({
            nama: get_auth.nama_lengkap,
        });

    }

    render(): React.ReactNode {
        return (
            <>
                <LoadingFull display={this.state.loading} />

                <NavbarResult />
                <div className="container">
                    <div className="result-box">
                        <div className="result-title">
                            <h3>Hasil Asesmen RIASEC,</h3>
                            <h5>{this.state.nama}</h5>
                        </div>

                        <div className="d-flex mt-4 mb-4">
                            <div className="align-self-center">
                                <Icon icon={"clarity:date-solid"} className="text-purple" style={{ position: "relative", top: -2, fontSize: 18 }} />
                            </div>

                            <div className="ps-2">
                                <p className="mb-0 text-extrabold text-purple">Dikerjakan Tanggal {this.state.tgl}</p>
                            </div>
                        </div>


                        <div className="hr-custom"></div>


                        {
                            this.state.loading && <><div className="d-flex align-items-center mt-4">
                                <strong>Sedang Mengambil Data...</strong>
                                <div className="spinner-border ms-auto text-primary" role="status" aria-hidden="true"></div>
                            </div></>
                        }

                        <ResultValueRiasec result={this.state.result_hasil} />

                        <ImageResultRiasec result={this.state.max_value} />

                        <div className="mt-4">
                            <h3 className="text-blacks mb-3">Keterangan Warna</h3>
                            <ul className="list-group">
                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg bg-red"></div>
                                            <span>Merah</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">RIASEC Sangat Dominan</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg bg-yellow"></div>
                                            <span>Kuning</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">RIASEC Dominan</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="list-group-item bg-blue-gradient keterangan-warna mb-3">
                                    <div className="d-flex">
                                        <div className="keterangan-warna-item flex-grow-1">
                                            <div className="keterangan-polagram-bg"></div>
                                            <span>Putih</span>
                                        </div>
                                        <div className="keterangan-warna-item-p">
                                            <p className="text-end">RIASEC Kurang Dominan</p>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>

                        <div className="mt-4">
                            <h3 className="text-blacks mb-3">Penjelasan Tipe kepribadian</h3>

                            <AccordionRiasec data={this.state.result_hasil} />
                        </div>
                    </div>
                </div>
                <Copyright style={{ marginBottom: 40 }} />

            </>
        )
    }
}

export default withRouter(ResultRiasec);