import React from "react";

class SoalIq extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            checked: "",
            total_soal: 0,

            list_soal: [],
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(jwb) {
        this.setState({
            checked: jwb
        });


        const answer = [];
        const getanswer = localStorage.getItem("answer");
        // [[id_pertanyaan, id_jawaban]]
        const arr_empty = JSON.parse(localStorage.getItem("arr_empty"));

        const checked_value = parseInt(jwb);
        const pastanswer = JSON.parse(getanswer);
        const soal_id = [];



        // get id_pertanyaan
        pastanswer.forEach(el => {
            soal_id.push(el[0]);
        });

        if (pastanswer.length === 0) {
            pastanswer.push([this.props.data_soal.id_pertanyaan, checked_value]);
            arr_empty.push([this.props.data_soal.id_pertanyaan, checked_value]);

        } else {
            const filter_answer = soal_id.some((el) => el === this.props.data_soal.id_pertanyaan);

            if (filter_answer) {
                pastanswer.map(el1 => {
                    if (el1[0] === this.props.data_soal.id_pertanyaan) {

                        el1[1] = checked_value;
                    }
                });
            } else {
                pastanswer.push([this.props.data_soal.id_pertanyaan, checked_value]);
                arr_empty.push([this.props.data_soal.id_pertanyaan, checked_value]);

            }
            // set

        }
        localStorage.setItem("answer", JSON.stringify(pastanswer));
        localStorage.setItem("arr_empty", JSON.stringify(arr_empty))
        this.props.handleDisabled(false);
        // const getChecked = (e.target.getAttribute("aria-checked") === 'true');
        // e.target.setAttribute("aria-checked", !getChecked)
    }

    soalFunc() {
        const data_soal = this.props.data_soal.Option.map((el, i) => {
            return (
                <>
                    <div className={`picture-choose-item me-2 ${this.state.checked === el.id_jawaban.toString() ? ` active-picture` : `border-grey`} mt-2`} aria-checked={this.state.checked === el.id_jawaban.toString()}
                        tabIndex={0}
                        onClick={() => this.handleClick(el.id_jawaban.toString())}
                        aria-labelledby="ariaLabel"
                        data-value={el.id_jawaban.toString()}>
                        <p className="text-14 mt-0 text-black" dangerouslySetInnerHTML={{ __html: el.jawaban }}></p>
                    </div>
                </>
            )
        });
        return data_soal;
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevState.checked !== this.state.checked) {


            const data_soal = this.soalFunc();
            this.setState({
                list_soal: data_soal,
            })
        }

        if (prevProps.data_soal !== this.props.data_soal) {

            this.props.handleDisabled(true);

            const data_soal = this.soalFunc();
            this.setState({
                list_soal: data_soal,
            })
        }
    }

    componentDidMount(): void {
        const get_pagination = JSON.parse(localStorage.getItem("pagination-rapidtest"));
        this.setState({
            total_soal: get_pagination.jumlah_soal,
        });



        const data_soal = this.soalFunc();

        this.setState({
            list_soal: data_soal,
        })


        if (this.props.answer[1] !== null) {
            if (this.props.data_soal.id_pertanyaan === this.props.answer[0]) {
                this.setState({
                    checked: this.props.answer[1].toString()
                });
            }
        }
    }


    render(): React.ReactNode {
        return (
            <>
                <div className="soal-gaya mt-2">
                    <div className="soal-gaya-content">
                        <span>Pertanyaan {this.props.no_soal} dari {this.state.total_soal} | Subtest {this.props.hal}</span>
                        <p className="text-bold text-black" dangerouslySetInnerHTML={{ __html: this.props.data_soal.soal }}></p>

                        <div className="picture-choose justify-content-center">
                            {
                                this.state.list_soal
                            }
                        </div>
                    </div>
                    <div className="hr-custom mt-3"></div>

                </div>
            </>
        )
    }
}

export default SoalIq;