import React from 'react';

class NavbarTes extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            img: "",
            soal: "102",
        }

        this.getSoal = this.getSoal.bind(this);
    }

    getSoal() {
        if (this.props.title === "Polagram") {
            this.setState({
                img: "polagram_card",
            });
        } else if (this.props.title === "Gaya Belajar") {
            this.setState({
                img: "tes_gaya_belajar_card",
            });
        } else if (this.props.title === "RIASEC") {
            this.setState({
                img: "riasec_card",
            });
        } else if (this.props.title === "Kepribadian") {
            this.setState({
                img: "kepribadian_card",
            });
        } else if (this.props.title === "IQ") {
            this.setState({
                img: "iq_card",
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.props.title !== prevProps.title) {
            this.getSoal();
        }
    }

    componentDidMount(): void {
        this.getSoal();
    }

    render(): React.ReactNode {
        return (
            <>
                <div className={`navbar-tes gradient-${this.state.img}`}>
                    <div className='container'>
                        <div className='navbar-tes-content'>
                            <div className='d-flex'>
                                <div className='flex-grow-1'>
                                    {this.props.warning === true ? <><img src="/images/modal_warning.png" alt="" width={40} /></> : <div className='navbar-tes-title'>
                                        <p>Asesmen {this.props.title}</p>
                                        <small>{this.props.total_soal} Pertanyaan</small>
                                    </div>}
                                </div>
                                <div>
                                    <div className='navbar-tes-img'>
                                        <img src={`/images/${this.state.img}.png`} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NavbarTes;