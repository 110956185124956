import React from "react";
import NavbarIsi from "./NavbarIsi";
import FooterButton from "./FooterButton";
import HrCustom from "../etc/HrCustom";
import { withRouter } from "../etc/withRouter";
import ProfileModule from "../module/Profile";
import Auth from "../module/Auth";
import LoadingFull from "../etc/LoadingFull";

class Step3 extends React.Component<any, any> {
    constructor(props) {
        super(props);


        this.state = {
            comp_kelas: "",
            comp_jurusan: "",
            data: {
                kelas: {
                    id_kelas: ""
                },
                jurusan: {
                    id_jurusan: ""
                },
                sekolah: "",
                nis: "",
            },
            loading: true,
            cita_cita: [],
            kesukaan: [],
        }

        this.getJurusan = this.getJurusan.bind(this);
        this.getKelas = this.getKelas.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getLocal = this.getLocal.bind(this);
        this.handleNext = this.handleNext.bind(this);

    }

    getJurusan() {
        const data_auth = localStorage.getItem("user-rapidtest");
        ProfileModule.getJurusan(data_auth).then((result: any) => {

            if (result.response === true) {
                const r = result.data.result.map(el => {
                    return (
                        <>
                            <option value={el.id}>{el.name}</option>
                        </>
                    )
                });

                this.setState({
                    jurusan_comp: r,
                })
            }
        });
    }

    getProfile() {
        this.setState({
            loading: true,
        })
        const data_auth = localStorage.getItem("user-rapidtest");
        Auth.checkAuth(data_auth).then((result: any) => {
            if (result.response === true) {
                this.setState(prevState => ({
                    data: {
                        ...prevState.data,
                        kelas: {
                            id_kelas: result.data.result.sekolah.Kelas.id_kelas,
                            nama_kelas: result.data.result.sekolah.Kelas.nama_kelas
                        },
                        jurusan: {
                            id_jurusan: result.data.result.sekolah.Jurusan.id_jurusan,
                            nama_jurusan: result.data.result.sekolah.Jurusan.nama_jurusan,
                        },
                        sekolah: result.data.result.sekolah.sekolah,
                        nis: result.data.result.sekolah.nis
                    },
                    loading: false,
                }))
            }
        });
    }

    getKelas() {
        const data_auth = localStorage.getItem("user-rapidtest");
        ProfileModule.getKelas(data_auth).then((result: any) => {

            if (result.response === true) {
                const r = result.data.result.map(el => {
                    return (
                        <>
                            <option value={el.id_kelas}>{el.name}</option>
                        </>
                    )
                });

                this.setState({
                    kelas_comp: r,
                })
            }
        });
    }


    getLocal() {
        const get_local = JSON.parse(localStorage.getItem("step_profile"))
        const cita = get_local.cita_cita.map(el => {
            return {
                id: el,
                text: el,
            }
        });

        const kesukaan = get_local.kesukaan.map(el => {
            return {
                id: el,
                text: el,
            }
        });

        this.setState({
            cita_cita: cita,
            kesukaan: kesukaan,
        });
    }

    handleNext() {
        const get_local = JSON.parse(localStorage.getItem("step_profile"));
        get_local.school = this.state.data.sekolah;
        get_local.kelas = this.state.data.kelas.id_kelas;
        get_local.jurusan = this.state.data.jurusan.id_jurusan;

        localStorage.setItem("step_profile", JSON.stringify(get_local));

    }

    componentDidMount(): void {
        this.getProfile();
        this.getJurusan();
        this.getKelas();
        this.getLocal();
    }

    render(): React.ReactNode {
        return (
            <>
                <LoadingFull display={this.state.loading} />

                <div className="container">
                    <NavbarIsi step={3} />

                    <div className="box-profile">
                        <h5>Silahkan Isi Info Sekolah Kamu.</h5>

                        <HrCustom icon="school" />

                        <div className="isi-pp mt-4">
                            <div className="form-group">
                                <label htmlFor="">Sekolah</label>
                                <input type="text" className="form-control" value={this.state.data.sekolah} placeholder="Masukan Nama Sekolah Kamu" disabled />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">NIS</label>
                                <input type="text" className="form-control" value={this.state.data.nis} disabled />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">Kelas</label>
                                <select name="" className="form-control" id="">
                                    <option value={this.state.data.kelas.id_kelas}>{this.state.data.kelas.nama_kelas}</option>
                                    {this.state.kelas_comp}
                                </select>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">Jurusan</label>
                                <select name="" className="form-control" placeholder="Masukan Jurusan Kamu" id="">
                                    <option value={this.state.data.jurusan.id_jurusan}>{this.state.data.jurusan.nama_jurusan}</option>

                                    {this.state.jurusan_comp}
                                </select>
                            </div>
                        </div>

                    </div>
                </div>

                {
                    this.state.loading === false ? <FooterButton to="/step4" openModal={this.handleNext} to_2="/step2" btn_second="Kembali" /> : <></>
                }
            </>
        )
    }
}

export default withRouter(Step3);