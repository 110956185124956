import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Time from "../tes/iq/Time";

class ModalShowPicture extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal show={this.props.isOpen} className="img-modals" backdrop={'static'} scrollable={true}>
                    <Modal.Body>
                        <div className="header-modal">
                            <img src="/images/modal_warning.png" alt="" />
                        </div>

                        <div className="mt-4">
                            <Time timer={this.props.timer} />
                        </div>

                        <div className="modal-message">
                            <div className="images-show">
                                <img src={this.props.gambar} alt="" />
                            </div>
                        </div>

                        {/* <div className="mt-4 text-end">
                            <button className="btn btn-info text-uppercase ps-4 pe-4" onClick={this.props.closeModal}>KEMBALI</button>
                        </div> */}
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ModalShowPicture;