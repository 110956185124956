import React from "react";

class ResultValueGayaBelajar extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            list_value: "",
        }


    }

    getHasil() {
        if (this.props.result.length !== 0) {

            const hasil = this.props.result.data.map((el) => {
                return (
                    <>
                        <div className={`value-kepribadian-item width-88 bg-${el.warna} me-2`}>
                            <h5>{el.score}</h5>
                            <p>{el.nama}</p>
                        </div>
                    </>
                )
            });
            this.setState({
                list_value: hasil
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.result !== this.props.result) {
            this.getHasil();
        }
    }

    componentDidMount(): void {
        this.getHasil();
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="value-kepribadian mt-4">
                    <div className="value-kepribadian-content">
                        <div className="d-flex justify-content-center">
                            {
                                this.state.list_value
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ResultValueGayaBelajar;