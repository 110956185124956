import React from 'react';

class Copyright extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className={`copyright ${this.props.className}`} style={this.props.style}>
                    <p className=''>&copy; 2023 Ambisius Academy All Rights Reserved</p>
                </div>
            </>
        )
    }
}

export default Copyright;