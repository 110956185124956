import React from "react";
import NavbarBack from "../etc/NavbarBack";
import Helmet from 'react-helmet';
import HrCustom from "../etc/HrCustom";
import Copyright from "../etc/Copyright";
import { Icon } from "@iconify/react";
import ModalChangePp from "../modal/ModalChangePp";
import Auth from "../module/Auth";
import LoadingFull from "../etc/LoadingFull";
import moment from "moment";
import ProfileModule from "../module/Profile";
import { WithContext as ReactTags } from 'react-tag-input';
import { data_cita } from "./DataTest";
import ModalConfirmProfile from "../modal/ModalConfirmProfile";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class EditProfile extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            data_user: {
                kelas: "",
                jurusan: "",
                nis: "",
                sekolah: "",
            },
            data_pribadi: {
                nama: "",
                email: "",
                no_telp: "",
                ttl: "",
                jk: "",
                alamat: "",
            },
            cita: [],
            kesukaan: [],
            loading: true,
            jurusan_comp: [],
            kelas_comp: [],
            tags_cita: [],
            tags_kesukaan: [],
            img: "",
            isOpenConfirm: false,
            value_cita: "",
            value_kesukaan: "",
            suggest_cita: [{ id: "", text: "" }],
            suggest_kesukaan: [{ id: "", text: "" }],
        }

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
        this.getJurusan = this.getJurusan.bind(this);
        this.getKelas = this.getKelas.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdditionKesukaan = this.handleAdditionKesukaan.bind(this);
        this.handleDeleteKesukaan = this.handleDeleteKesukaan.bind(this);
        this.handleSekolah = this.handleSekolah.bind(this);
        this.handleKelas = this.handleKelas.bind(this);
        this.handleJurusan = this.handleJurusan.bind(this);
        this.handleNama = this.handleNama.bind(this);
        this.handleTelp = this.handleTelp.bind(this);
        this.handleTtl = this.handleTtl.bind(this);
        this.handleJk = this.handleJk.bind(this);
        this.handleAlamat = this.handleAlamat.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.handleSimpan = this.handleSimpan.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeKesukaan = this.handleInputChangeKesukaan.bind(this);
        this.getSuggestionCita = this.getSuggestionCita.bind(this);
        this.getSuggestionKesukaan = this.getSuggestionKesukaan.bind(this);
    }

    getSuggestionCita() {
        const data_auth = localStorage.getItem("user-rapidtest");
        ProfileModule.getCita(data_auth).then((result: any) => {
            if (result.response === true) {
                const data_cita = result.data.map(el => {

                    return {
                        id: el.name,
                        text: el.name,
                    }
                });

                this.setState({
                    suggest_cita: data_cita
                })
            }
        })
    }

    getSuggestionKesukaan() {
        const data_auth = localStorage.getItem("user-rapidtest");
        ProfileModule.getKesukaan(data_auth).then((result: any) => {
            if (result.response === true) {
                const data_kesukaan = result.data.map(el => {
                    return {
                        id: el.name,
                        text: el.name,
                    }
                });

                this.setState({
                    suggest_kesukaan: data_kesukaan
                })
            }
        })
    }

    handleSimpan() {
        const data_auth = localStorage.getItem("user-rapidtest");
        const cita_arr = [];
        const kesukaan_arr = [];

        this.state.tags_cita.map(el => {
            cita_arr.push(el.text)
        })

        this.state.tags_kesukaan.map(el => {
            kesukaan_arr.push(el.text)
        })

        const data = {
            name: this.state.data_pribadi.nama,
            address: this.state.data_pribadi.alamat,
            phone: this.state.data_pribadi.no_telp,
            date: this.state.data_pribadi.ttl,
            jenkel: this.state.data_pribadi.jk,
            cita_cita: cita_arr,
            kesukaan: kesukaan_arr,
            school: this.state.data_user.sekolah,
            kelas: this.state.data_user.kelas.id_kelas.toString(),
            jurusan: this.state.data_user.jurusan.id_jurusan.toString(),
        }



        ProfileModule.sendChange(data_auth, data).then((result: any) => {

            if (result.response === true) {
                this.getProfile();
                this.closeConfirm();
            }
        });
    }

    getJurusan() {
        const data_auth = localStorage.getItem("user-rapidtest");
        ProfileModule.getJurusan(data_auth).then((result: any) => {

            if (result.response === true) {
                const r = result.data.result.map(el => {
                    return (
                        <>
                            <option value={el.id}>{el.name}</option>
                        </>
                    )
                });

                this.setState({
                    jurusan_comp: r,
                })
            }
        });
    }

    getKelas() {
        const data_auth = localStorage.getItem("user-rapidtest");
        ProfileModule.getKelas(data_auth).then((result: any) => {

            if (result.response === true) {
                const r = result.data.result.map(el => {
                    return (
                        <>
                            <option value={el.id_kelas}>{el.name}</option>
                        </>
                    )
                });

                this.setState({
                    kelas_comp: r,
                })
            }
        });
    }

    getProfile() {
        this.setState({
            loading: true,
            tags_cita: [],
            tags_kesukaan: [],
        })
        const data_auth = localStorage.getItem("user-rapidtest");
        Auth.checkAuth(data_auth).then((result: any) => {
            if (result.response === true) {

                // const date_clean = moment(result.data.result.info_pribadi.tgl_lahir.split("\"").join(''), "mm/dd/yyyy").format("yyyy-MM-DD");
                // 

                // for map cita & kesukaan
                result.data.result.Kesukaan.cita.map(el => {
                    this.setState(prevState => ({
                        tags_cita: [...prevState.tags_cita, { id: el, text: el }]
                    }))
                });

                result.data.result.Kesukaan.kesukaan.map(el => {
                    this.setState(prevState => ({
                        tags_kesukaan: [...prevState.tags_kesukaan, { id: el, text: el }]
                    }))
                });

                this.setState({
                    data_user: {
                        kelas: {
                            id_kelas: result.data.result.sekolah.Kelas.id_kelas,
                            nama_kelas: result.data.result.sekolah.Kelas.nama_kelas
                        },
                        jurusan: {
                            id_jurusan: result.data.result.sekolah.Jurusan.id_jurusan,
                            nama_jurusan: result.data.result.sekolah.Jurusan.nama_jurusan,
                        },
                        sekolah: result.data.result.sekolah.sekolah,
                        nis: result.data.result.sekolah.nis
                    },
                    data_pribadi: {
                        nama: result.data.result.info_pribadi.nama,
                        email: result.data.result.info_pribadi.email,
                        no_telp: result.data.result.info_pribadi.no_hp,
                        ttl: result.data.result.info_pribadi.tgl_lahir,
                        jk: result.data.result.info_pribadi.gender,
                        alamat: result.data.result.info_pribadi.alamat,
                    },
                    cita: result.data.result.Kesukaan.cita.toString(),
                    kesukaan: result.data.result.Kesukaan.kesukaan.toString(),
                    loading: false,
                    img: result.data.result.info_pribadi.foto,
                });
            }
        })
    }

    openModal() {
        this.setState({
            isOpen: true,
        });
    }

    closeModal() {
        this.setState({
            isOpen: false,
        })
    }

    openConfirm() {
        this.setState({
            isOpenConfirm: true,
        });
    }

    closeConfirm() {
        this.setState({
            isOpenConfirm: false,
        })
    }

    componentDidMount(): void {
        this.getProfile();
        this.getJurusan();
        this.getKelas();
        this.getSuggestionCita();
        this.getSuggestionKesukaan();
    }

    handleAddition(tag) {

        if (this.state.tags_cita.length !== 3) {
            this.setState(prevState => ({
                tags_cita: [...prevState.tags_cita, tag],
                value_cita: "",
                value_kesukaan: "",
            }), () => {

            });
        }
        const input = document.getElementById("cita_input") as HTMLInputElement
        input.value = "";
    }

    handleDelete(i) {
        const fil = this.state.tags_cita.filter((tag, index) => index !== i);

        this.setState(prevState => ({
            tags_cita: fil
        }))
    }

    handleAdditionKesukaan(tag) {
        if (this.state.tags_kesukaan.length !== 3) {
            this.setState(prevState => ({
                tags_kesukaan: [...prevState.tags_kesukaan, tag],
                value_cita: "",
                value_kesukaan: "",
            }));
        }
        const input = document.getElementById("kesukaan_input") as HTMLInputElement
        input.value = "";
    }

    handleDeleteKesukaan(i) {
        const fil = this.state.tags_kesukaan.filter((tag, index) => index !== i);

        this.setState(prevState => ({
            tags_kesukaan: fil
        }))
    }

    // form handle
    handleSekolah(e) {
        this.setState(prevState => ({
            data_user: {
                ...prevState.data_user,
                sekolah: e.target.value,
            }
        }));
    }

    handleKelas(e) {
        this.setState(prevState => ({
            data_user: {
                ...prevState.data_user,
                kelas: {
                    id_kelas: e.target.value
                },
            }
        }));
    }

    handleJurusan(e) {
        this.setState(prevState => ({
            data_user: {
                ...prevState.data_user,
                jurusan: {
                    id_jurusan: e.target.value
                },
            }
        }));
    }

    handleNama(e) {
        this.setState(prevState => ({
            data_pribadi: {
                ...prevState.data_pribadi,
                nama: e.target.value,
            }
        }));
    }

    handleTelp(e) {
        this.setState(prevState => ({
            data_pribadi: {
                ...prevState.data_pribadi,
                no_telp: e.target.value,
            }
        }));
    }

    handleTtl(e) {
        this.setState(prevState => ({
            data_pribadi: {
                ...prevState.data_pribadi,
                ttl: e.target.value,
            }
        }));
    }

    handleJk(e) {
        this.setState(prevState => ({
            data_pribadi: {
                ...prevState.data_pribadi,
                jk: e.target.value,
            }
        }));
    }

    handleAlamat(e) {
        this.setState(prevState => ({
            data_pribadi: {
                ...prevState.data_pribadi,
                alamat: e.target.value,
            }
        }));
    }
    // end form handle

    handleInputChange(e) {
        this.setState({
            value_cita: e
        });
    }

    handleInputChangeKesukaan(e) {
        this.setState({
            value_kesukaan: e
        });
    }

    render(): React.ReactNode {
        return (
            <>
                <LoadingFull display={this.state.loading} />
                <Helmet>
                    <title>Edit Profile | Ambisius Meter</title>
                </Helmet>
                <div className="container">
                    <NavbarBack to="/profile" />

                    <div className="isi-pp mt-5">
                        <div className="substract-1">
                            <img src="./images/circle_profile.png" alt="" />
                        </div>
                        <div className="substract-2">
                            <img src="./images/circle_profile.png" alt="" />
                        </div>

                        <div className="text-center img-isi-pp">
                            <img src={this.state.img} alt="" />
                            <div className="button-edit-pp">
                                <button className="btn btn-primary pt-1 pb-1 ps-2 pe-2 btn-edit-pp" onClick={this.openModal}><Icon icon={"ci:edit"} /></button>
                            </div>

                        </div>
                    </div>

                    <div className="mt-5">
                        <HrCustom icon="rocket" />

                        <div className="form-group mt-3">
                            <label htmlFor="">Cita - Cita</label>
                            <div>
                                <div className="btn-tags-container">
                                    <Button className="btn btn-primary btn-tags btn-sm" onClick={() => this.handleAddition({ id: this.state.value_cita, text: this.state.value_cita })}>Tambah</Button>
                                </div>
                                <ReactTags
                                    tags={this.state.tags_cita}
                                    id="cita_input"
                                    suggestions={this.state.suggest_cita}
                                    delimiters={delimiters}
                                    handleAddition={this.handleAddition}
                                    inputFieldPosition="top"
                                    handleDelete={this.handleDelete}
                                    autocomplete
                                    placeholder={"Masukan Cita Cita Kamu"}
                                    handleInputChange={this.handleInputChange}
                                />

                            </div>
                        </div>

                        <div className="form-group mt-3 mb-3">
                            <label htmlFor="">Kesukaan</label>
                            <div>
                                <div className="btn-tags-container">
                                    <Button className="btn btn-primary btn-tags btn-sm" onClick={() => this.handleAdditionKesukaan({ id: this.state.value_kesukaan, text: this.state.value_kesukaan })}>Tambah</Button>
                                </div>
                                <ReactTags
                                    tags={this.state.tags_kesukaan}
                                    delimiters={delimiters}
                                    suggestions={this.state.suggest_kesukaan}
                                    handleAddition={this.handleAdditionKesukaan}
                                    inputFieldPosition="top"
                                    handleDelete={this.handleDeleteKesukaan}
                                    autocomplete
                                    placeholder={"Masukan Hobi/Kesukaan Kamu"}
                                    handleInputChange={this.handleInputChangeKesukaan}
                                    id="kesukaan_input"

                                />


                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <HrCustom icon="account_circle" />

                        <div className="form-group mt-3">
                            <label htmlFor="">Email</label>
                            <input type="text" className="form-control" disabled value={this.state.data_pribadi.email} />
                        </div>
                        <div className="text-end">
                            <Link to="/ganti_password" className="btn btn-info btn-sm ps-4 pt-2 pb-2 pe-4 mt-3 ">UBAH PASSWORD</Link>
                        </div>
                    </div>

                    <div className="mt-5">
                        <HrCustom icon="school" />

                        <div className="form-group mt-3">
                            <label htmlFor="">Sekolah</label>
                            <input type="text" onChange={this.handleSekolah} disabled className="form-control" value={this.state.data_user.sekolah} placeholder="Masukan Nama Sekolah Kamu" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="">NIS</label>
                            <input type="text" className="form-control" disabled value={this.state.data_user.nis} />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="">Kelas</label>
                            <select name="" onChange={this.handleKelas} className="form-control" id="">
                                <option value={this.state.data_user.kelas.id_kelas}>{this.state.data_user.kelas.nama_kelas}</option>
                                {this.state.kelas_comp}
                            </select>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="">Jurusan</label>
                            <select name="" onChange={this.handleJurusan} className="form-control" placeholder="Masukan Jurusan Kamu" id="">
                                <option value={this.state.data_user.jurusan.id_jurusan}>{this.state.data_user.jurusan.nama_jurusan}</option>

                                {this.state.jurusan_comp}
                            </select>
                        </div>
                    </div>

                    <div className="mt-5">
                        <HrCustom icon="face_man" />

                        <div className="form-group mt-3">
                            <label htmlFor="">Nama Lengkap</label>
                            <input type="text" onChange={this.handleNama} className="form-control" value={this.state.data_pribadi.nama} placeholder="Masukan Nama Lengkap Kamu" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="">Nomor Telepon</label>
                            <input type="text" onChange={this.handleTelp} className="form-control" value={this.state.data_pribadi.no_telp} placeholder="Masukan Nomor Telepon Kamu" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="">Tanggal Lahir</label>
                            <input type="date" onChange={this.handleTtl} className="form-control" value={this.state.data_pribadi.ttl} placeholder="Masukan Nomor Telepon Kamu" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="">Jenis Kelamin</label>
                            <select name="" onChange={this.handleJk} className="form-control" placeholder="Masukan Jurusan Kamu" id="">
                                <option value={this.state.data_pribadi.jk}>{this.state.data_pribadi.jk === "L" ? "Laki - Laki" : "Perempuan"}</option>
                                <option value="L">Laki - Laki</option>
                                <option value="P">Perempuan</option>
                            </select>
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor="">Alamat</label>
                            <textarea name="" onChange={this.handleAlamat} className="form-control form-text-area" id="" cols={50} rows={10} value={this.state.data_pribadi.alamat}></textarea>
                        </div>
                    </div>
                </div>

                <div className="bg-blue-gradient d-grid mt-2">
                    <button className="btn btn-primary" onClick={this.openConfirm}>SIMPAN PERUBAHAN</button>
                </div>
                <Copyright style={{ marginBottom: 30, marginTop: 30 }} />
                <ModalChangePp isOpen={this.state.isOpen} closeModal={this.closeModal} getProfile={this.getProfile} img={this.state.img} />
                <ModalConfirmProfile isOpen={this.state.isOpenConfirm} closeModal={this.closeConfirm} handleSimpan={this.handleSimpan} />

            </>
        )
    }
}

export default EditProfile;