import React from "react";

class InfoSoal extends React.Component<any, any> {
    constructor(props) {
        super(props);

    }

    render(): React.ReactNode {
        return (
            <>
                <div className="row mt-4">
                    <div className="col col-sm">
                        <div className="detail-info-box">
                            <div className="d-flex">
                                <div className="align-self-center pe-2">
                                    <img src="/images/timer.png" alt="" />
                                </div>
                                <div>
                                    <span>Waktu</span>
                                    <p>{this.props.data_tes.waktu === 0 ? "Tidak Diwaktu" : this.props.data_tes.waktu + " Menit"}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col col-sm">
                        <div className="detail-info-box">
                            <div className="d-flex">
                                <div className="align-self-center pe-2">
                                    <img src="/images/question.png" alt="" />
                                </div>
                                <div>
                                    <span>Jumlah Soal</span>
                                    <p>{this.props.data_tes.total_soal} Soal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default InfoSoal;