import { Icon } from "@iconify/react";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ModalLogout from "../modal/ModalLogout";


class Sidebar extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    closeModal() {
        this.setState({
            isOpen: false,
        })
    }

    openModal() {
        this.setState({
            isOpen: true,
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Offcanvas show={this.props.isSidebar} onHide={this.props.closeSidebar}>
                    <Offcanvas.Body>
                        <div className="sidebar-content">
                            <div className="sidebar-header">
                                <div className="d-flex">
                                    <div className="align-self-center">
                                        <img src="./images/sidebar_icon_2.png" alt="" />
                                    </div>
                                    <div className="align-self-center flex-grow-1 ms-2 ">
                                        <div className="sidebar-title">
                                            <p>Daftar Menu</p>
                                        </div>
                                    </div>
                                    <div onClick={this.props.closeSidebar} className="align-self-center"><img src="./images/back_sidebar.png" alt="" /></div>
                                </div>
                            </div>

                            <div className="sidebar-body">
                                <div className="img-isi-pp">
                                    <img src={this.props.img} alt="" />
                                </div>

                                <div className="mt-3">
                                    <h3>{this.props.nama}</h3>
                                    <p>NIS. {this.props.nis}</p>
                                </div>
                            </div>
                        </div>

                        <div className="list-sidebar">
                            <NavLink to={"/dashboard"} className={({ isActive }) => isActive ? "list-sidebar-item active" : "list-sidebar-item"}><Icon icon="ant-design:home-filled" /> DASHBOARD</NavLink>
                            <NavLink to={"/asesmen"} className={({ isActive }) => isActive ? "list-sidebar-item active" : "list-sidebar-item"}><Icon icon="healthicons:i-exam-multiple-choice" /> ASESMEN</NavLink>
                            <NavLink to={"/profile"} className={({ isActive }) => isActive ? "list-sidebar-item active" : "list-sidebar-item"}><Icon icon="mdi:face-man-profile" /> PROFILE</NavLink>
                        </div>

                        <div className="list-sidebar bottom">
                            <div className="list-sidebar-item disabled"><a href="#" onClick={this.openModal} className="list-sidebar-item" > <Icon icon="ri:logout-circle-fill" /> LOGOUT</a></div>
                        </div>

                    </Offcanvas.Body>
                </Offcanvas>

                <ModalLogout isOpen={this.state.isOpen} closeModal={this.closeModal} />
            </>
        )
    }
}

export default Sidebar;