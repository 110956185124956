import React from "react";

class HrCustom extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="d-flex w-100 mt-4">
                    <div className="flex-grow-1 align-self-center">
                        <div className="hr-opacity"></div>
                    </div>
                    <div className="pe-4 ps-4">
                        <img src={`./images/${this.props.icon}.png`} alt="" />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                        <div className="hr-opacity"></div>
                    </div>
                </div>
            </>
        )
    }
}

export default HrCustom;